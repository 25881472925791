
export default function firstN(obj, n) {
  if (!obj) {
    return obj;
  } else {
    return Object.keys(obj)
      .sort()
      .slice(0, n)
      .reduce(function (memo, current) {
        memo[current] = obj[current];
        return memo;
      }, {});
  }
}

export function findDiff(str1, str2) {
  let diff = '';
  str2.split('').forEach(function (val, i) {
    if (val !== str1.charAt(i)) diff += val;
  });
  return diff;
}

export function patternToText(pattern, regex) {
  let text = '^';
  let patternStart = pattern.indexOf('%');
  let extension = pattern.lastIndexOf('_EXT');
  let end = pattern.lastIndexOf('.');

  if (patternStart > -1) {
    text = text.concat(pattern.substring(0, patternStart));
    text = text.replace('-', '-\\');
    let allPatterns = null;
    if (extension === -1 && end === -1) {
      allPatterns = pattern.substring(patternStart);
    } else {
      allPatterns = pattern.substring(
        patternStart,
        extension > -1 ? extension : end
      );
    }
    allPatterns = allPatterns.replace(/%[+/-/*]O/g, '');
    allPatterns = allPatterns.replace(/%C/g, '');
    allPatterns = allPatterns.replace(/%Y/g, '[1-3][0-9]{3}');
    allPatterns = allPatterns.replace(/%y/g, '[0-9]{2}');
    allPatterns = allPatterns.replace(/%m/g, '[0-1][0-9]');
    allPatterns = allPatterns.replace(
      /%B/g,
      '(?:january|february|march|april|may|june|july|august|september|october|november|december)'
    );
    allPatterns = allPatterns.replace(
      /%b/g,
      '(?:jan|feb|mar|apr|may|jun|jul|aug|sep|oct|nov|dec)'
    );
    allPatterns = allPatterns.replace(/%d/g, '[0-3][0-9]');
    allPatterns = allPatterns.replace(/%H/g, '[0-2][0-9]');
    allPatterns = allPatterns.replace(/%M/g, '[0-5][0-9]');
    allPatterns = allPatterns.replace(/%S/g, '[0-5][0-9]');
    allPatterns = allPatterns.replace(/%p/g, '(?:am|pm)');
    allPatterns = allPatterns.replace(/%e/g, '.');
    text = text.concat(allPatterns);
    let regexExtension = regex.lastIndexOf('_EXT');
    let regexEnd = regex.lastIndexOf('.');
    text = text.concat(
      regex.substring(regexExtension > -1 ? regexExtension : regexEnd)
    );
  }
  return text;
}

export function anythingElse(inputToCheck, allowStrings) {
  if (!inputToCheck || !allowStrings) {
    return true;
  } else {
    inputToCheck = inputToCheck
      .replace(/\s+/g, '')
      .replace(/,/g, ' ')
      .toLowerCase();
    allowStrings = allowStrings
      .replace(/\s+/g, '')
      .replace(/,/g, ' ')
      .toLowerCase();
  }
  if (inputToCheck.length > allowStrings.length) {
    return true;
  }
  inputToCheck.split(' ').forEach(function (val, i) {
    if (allowStrings.indexOf(val) === -1) {
      return true;
    }
  });
  return false;
}

/*
 * @param data in following 2 functions is a comma-separated list of alternating indicators and
 * corresponding claims paid amounts, e.g. "B,1234.12,G,4321.43,U,123.32"
 */
export function significantUnexpectedIndicators(data, expectedIndicators) {
  let indicatorsAndClaimsPaidArray = data.split(',');
  const totalClaimsPaidComparator =
    extractTotalClaimsPaidFromIndicatorData(indicatorsAndClaimsPaidArray) / 10;
  for (let i = 0; i < indicatorsAndClaimsPaidArray.length; i = i + 2) {
    if (
      expectedIndicators.indexOf(indicatorsAndClaimsPaidArray[i]) === -1 &&
      indicatorsAndClaimsPaidArray[i + 1] > totalClaimsPaidComparator
    ) {
      return true;
    }
  }
  return false;
}

export function displayIndicatorsAsPercent(data) {
  if (!data) return '';
  let indicatorsAndClaimsPaidArray = data.split(',');
  let displayString = '';
  let totalClaimsPaid = extractTotalClaimsPaidFromIndicatorData(
    indicatorsAndClaimsPaidArray
  );
  for (let i = 0; i < indicatorsAndClaimsPaidArray.length; i = i + 2) {
    displayString +=
      ', ' +
      indicatorsAndClaimsPaidArray[i] +
      ': ' +
      ((100 * indicatorsAndClaimsPaidArray[i + 1]) / totalClaimsPaid).toFixed(
        2
      ) +
      '%';
  }
  return displayString.substring(2);
}

function extractTotalClaimsPaidFromIndicatorData(dataArray) {
  let totalClaimsPaid = 0;
  for (let i = 1; i < dataArray.length; i = i + 2) {
    totalClaimsPaid += parseFloat(dataArray[i]);
  }
  return totalClaimsPaid;
}

export function generateRandomString(length = 8) {
  const alphabetDictionary =
    'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';

  return new Array(length)
    .fill(0)
    .map(() =>
      alphabetDictionary.charAt(
        Math.floor(Math.random() * alphabetDictionary.length)
      )
    )
    .join('');
}

export function hasSysRole(props, aRole) {
  return props && props.sysRoles && props.sysRoles.includes(aRole);
}

export function hasAnySysRole(props, roleArray) {
  return (
    props &&
    props.sysRoles &&
    props.sysRoles.some((r) => roleArray.indexOf(r) >= 0)
  );
}

// currentRoles is a comma seperated list of user roles
export function hasAnyCurrentRole(currentRoles, roleArray) {
  const roleSet = new Set();
  if (!currentRoles) {
    return false;
  }
  currentRoles.split(',').forEach((role) => roleSet.add(role));
  return roleArray.some((role) => roleSet.has(role));
}

export function shallowCompare(obj1, obj2) {
  Object.keys(obj1).length === Object.keys(obj2).length &&
    Object.keys(obj1).every(
      (key) => obj2.hasOwnProperty(key) && obj1[key] === obj2[key]
    );
}

export const hasPermission = (permission, permissionList) => {
  return permissionList && Array.isArray(permissionList)
    ? permissionList.includes(permission)
    : false;
};

/**
 * Checks if the user has permission to refresh tenant data.
 *
 * @param {Object} user - The user object.
 * @param {boolean} user.sysAdmin - Indicates if the user is a system administrator.
 * @param {boolean} user.foAdmin - Indicates if the user is a FO administrator.
 * @param {Array} user.companies - The list of companies associated with the user.
 * @param {Array} user.permissions - The list of permissions assigned to the user.
 * @returns {boolean} True if the user has permission to refresh tenant data, otherwise false.
 */
export const hasTenantDataRefreshPermission = (user) => {
  const hasFOPermission = user.permissions && user.permissions.some(permission => permission.startsWith('FO_'));
  return user.sysAdmin || user.foAdmin || (user.companies && user.companies.length > 0) || hasFOPermission;
};
