import { AppConfig } from 'AppConfig';
import axios from 'axios';
import { CASE_STATUS_LIST, DIRECTION_ASC } from 'common/Constant';
import { APPSTATE_SET_APP_ERROR } from 'store/actions/appstate';
import * as Types from './types';

export const fetchAllPlanYears = () => {
  return (dispatch) => {
    axios
      .get(AppConfig.CLIENT_PORTAL_URL + `/case-management/years`)
      .then((res) => {
        dispatch({
          type: Types.FETCH_ALL_MD_RENEWAL_YEARS_SUCCESS,
          data: res
        });
      })
      .catch((err) => {
        console.log('error' + err);
      });
  };
};

export const fetchTenantList = () => {
  return (dispatch) => {
    axios.get(AppConfig.IAM_BACKEND_URL + `/tenant-admin/companies`).then((res) => {
      dispatch({
        type: Types.FETCH_TENANT_LIST,
        data: res.data
      });
    });
  };
};

export const getMdRenewalManagementTableData = (query, planYears, selectedStatus, updateViewOnly) =>
  new Promise((resolve, reject) => {
    const ALL_YEARS = 'All';
    const ALL_UNDONE = 'All Undone';
    const { tableRef, rowIndex, field, newValue } = query;

    if (updateViewOnly) {
      const data = tableRef.current.getRenderData();
      const tempData = [...data];
      tempData[rowIndex] = { ...tempData[rowIndex], [field]: newValue };
      tableRef.current.state.query = { ...query, updateViewOnly: false, rowIndex: '', field: '', newValue: '' };
      resolve({
        data: tempData,
        page: query?.page || tableRef?.current?.state?.currentPage,
        size: query?.pageSize || tableRef?.current?.state?.pageSize,
        totalCount: query?.totalCount || data.length
      });
    } else {
      axios
        .post(AppConfig.CLIENT_PORTAL_URL + `/case-management/search`, {
          page: query.page,
          size: query.pageSize,
          direction: query.orderDirection ? query.orderDirection.toUpperCase() : DIRECTION_ASC,
          sortProperty: query.orderBy ? query.orderBy.field : 'year',
          search: query.search,
          years: ALL_YEARS === planYears[0] ? [] : planYears,
          statuses: ALL_UNDONE === selectedStatus[0] ? CASE_STATUS_LIST.map(([firstItem]) => firstItem).slice(0, -1) : selectedStatus
        })
        .then((m) => {
          let data = m.data;
          resolve({
            data: data.content,
            page: data.number,
            size: data.size,
            totalCount: data.totalElements
          });
        })
        .catch((_) => {
          resolve({
            data: [],
            page: 0,
            totalCount: 0
          });
        });
    }
  });

export const updateRenewalCase = (id, caseStatus) => {
  return async (dispatch) => {
    try {
      const response = await axios.put(AppConfig.CLIENT_PORTAL_URL + `/case-management/update?id=${id}&status=${caseStatus}`);
      if (response) {
        dispatch({ type: Types.SEARCH_REFRESH });
      }
    } catch (error) {
      dispatch({ type: APPSTATE_SET_APP_ERROR, payload: error });
    }
  };
};

export const save = (data) => {
  return async (dispatch) => {
    try {
      const response = await axios.post(AppConfig.CLIENT_PORTAL_URL + `/case-management/save`, data);
      if (response) {
        dispatch({ type: Types.SEARCH_REFRESH });
      }
    } catch (error) {
      dispatch({ type: APPSTATE_SET_APP_ERROR, payload: error });
    }
  };
};

export const fetchFilesByCaseId = (caseId) => {
  return (dispatch) => {
    axios.get(AppConfig.CLIENT_PORTAL_URL + `/annual-renewal/file/list/s3/${encodeURIComponent(caseId)}`).then((res) => {
      dispatch({
        type: Types.FETCH_FILE_LIST,
        data: res.data
      });
    });
  };
};

export const clearFileList = () => ({
  type: Types.CLEAR_FILE_LIST
});
