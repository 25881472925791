import AutorenewIcon from '@mui/icons-material/Autorenew';
import CloseIcon from '@mui/icons-material/Close';
import DoneIcon from '@mui/icons-material/Done';
import HourglassEmptyIcon from '@mui/icons-material/HourglassEmpty';
import PriorityHighIcon from '@mui/icons-material/PriorityHigh';
import RemoveIcon from '@mui/icons-material/Remove';
import ReportOutlinedIcon from '@mui/icons-material/ReportOutlined';
import ReportProblemOutlinedIcon from '@mui/icons-material/ReportProblemOutlined';
import Box from '@mui/material/Box';
import { DEFAULT_FORMAT } from '../../utils/dateUtil';
import Chip from './components/DataGridChip';
import LinearProgress from './components/LinearProgress';

export default function RefreshDashboardColumns() {
  return [
    {
      field: 'tenantName',
      title: 'Tenant Name',
      cellStyle: { minWidth: 120 },
      customFilterAndSearch: (term, rowData) => rowData.tenantName.toLowerCase().includes(term.toLowerCase()),
      render: (rowData) => <span>{rowData.tenantName}</span>,
      filtering: false
    },
    {
      field: 'dataThruDate',
      title: 'Data Through Date',
      render: (rowData) => DEFAULT_FORMAT(rowData.dataThruDate),
      customSort: (a, b) => a.dataThruDate - b.dataThruDate,
      filtering: false,
      searchable: false
    },
    {
      field: 'scheduledRefreshDate',
      title: 'Estimated Refresh Date',
      render: (rowData) => DEFAULT_FORMAT(rowData.scheduledRefreshDate),
      customSort: (a, b) => a.scheduledRefreshDate - b.scheduledRefreshDate,
      filtering: false,
      searchable: false
    },
    {
      field: 'refreshProgress',
      title: 'Received Files',
      customSort: (a, b) => parseFloat(a.stagedFiles / a.totalFiles) - parseFloat(b.stagedFiles / b.totalFiles),
      render: (rowData) => {
        if (rowData.primaryBlockingIssue) {
          var color = 'lightSalmon';
          var icon = <ReportOutlinedIcon fontSize={'small'} />;
        } else if (rowData.lateFiles && rowData.lateFiles > 0) {
          var color = 'gold';
          var icon = <ReportProblemOutlinedIcon fontSize={'small'} />;
        } else {
          var color = 'lightGreen';
          var icon = null;
        }
        if (rowData.totalFiles && rowData.totalFiles === 0) {
          var color = '#E8E8E8';
        }
        return (
          <div
            style={{
              height: '100%',
              width: '100%',
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center'
            }}>
            <Box
              style={{
                height: '100%',
                width: '100%',
                maxWidth: '100px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                position: 'relative',
                padding: '6px'
              }}>
              <LinearProgress barcolor={color} variant="determinate" value={(rowData.stagedFiles / rowData.totalFiles) * 100} />
              <div
                style={{
                  position: 'absolute',
                  left: 0,
                  right: 0,
                  marginLeft: 'auto',
                  marginRight: 'auto',
                  width: '20px'
                }}>
                {rowData.stagedFiles + '/' + rowData.totalFiles}
              </div>
            </Box>
          </div>
        );
      },
      filtering: false,
      searchable: false
    },
    {
      field: 'refreshStatus',
      title: 'Refresh Status',
      render: (rowData) => {
        var color = 'silver';
        var icon = <RemoveIcon />;

        switch (rowData.refreshStatus) {
          case 'OK':
            icon = <DoneIcon color="white" />;
            color = 'limeGreen';
          case 'Complete':
            icon = <DoneIcon color="white" />;
            color = 'limeGreen';
            break;
          case 'Very Late':
            icon = <PriorityHighIcon color="white" />;
            color = 'red';
            break;
          case 'Late':
            icon = <CloseIcon color="white" />;
            color = 'darkOrange';
            break;
          case 'Pending':
            icon = <RemoveIcon color="white" />;
            color = 'silver';
            break;
          case 'Queued':
            icon = <HourglassEmptyIcon color="white" />;
            color = 'dodgerBlue';
            break;
          case 'Running':
            icon = <AutorenewIcon color="white" />;
            color = 'blueViolet';
            break;
          default:
            icon = <RemoveIcon color="white" />;
            color = 'royalBlue';
        }
        return (
          <div>
            <Chip icon={icon} label={rowData.refreshStatus} chipcolor={color} size={'small'} />
          </div>
        );
      },
      filtering: false,
      searchable: false
    }
  ];
}
