import React, { useState, useEffect, useRef } from 'react';
import { Box, Checkbox, Link } from '@material-ui/core';
import SyncAltIcon from '@material-ui/icons/SyncAlt';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import axios from 'axios';
import * as Constant from 'common/Constant';
import { AppConfig } from 'AppConfig';
import { store } from 'store/store';
import * as AppActionTypes from 'store/actions/appstate';
import UserPermissionDetailView from './components/detailView';
import { confirmDialog } from 'common/ComfirmationDialog';
import * as Action from './action/action';
import { useSelector } from 'react-redux';
import * as UserTypes from 'features/user/userTypes';
import GrantTenantAdminDialog from './components/grantTenantAdminDialog';
import CheckIcon from '@material-ui/icons/Check';
import ClearIcon from '@material-ui/icons/Clear';
import UserAccessManagementDialog from './components/userAccessManagementDialog';
import { getAllBrokers } from 'features/user/userActions';
import { fetchBrokerCompanyMapping } from '../vendor-labeling/actions/action';
import { FoMaterialTable } from 'common/FoMaterialTable';

const UserPermissionTable = () => {
  const tableRef = useRef();

  const appList = useSelector((state) => state.adminUserAccess.applicationList);
  const tenantList = useSelector((state) => state.adminUserAccess.tenantList);
  const shouldRefresh = useSelector(
    (state) => state.adminUserAccess.shouldRefresh
  );

  const [showUserAccessManagementDialog, setShowUserAccessManagementDialog] =
    useState(false);
  const [pageSize, setPageSize] = useState(Constant.DEFAULT_SELECT_PAGE_SIZE);
  const [detailpanelState, setdetailpanelState] = useState({});
  const [interalDetailPanelState, setInteralDetailPanelState] = useState({});

  const [showGrantTenantAdmin, setShowGrantTenantAdmin] = useState(false);
  const [tenantAdminTarget, setTenantAdminTarget] = useState(undefined);
  const userData = useRef({});

  const refresh = () => {
    tableRef.current && tableRef.current.onQueryChange();
  };

  const handleAdminCheckboxChange = (data) => {
    let { rowdata, adminCode, adminName, changedValue } = data;
    confirmDialog(
      () =>
        store.dispatch(
          Action.saveApplicationAdmin({
            userId: rowdata.id,
            adminCode: adminCode,
            changedValue: changedValue
          })
        ),
      changedValue
        ? 'Are you sure to grant the ' +
        adminName +
        ' permission to "' +
        rowdata?.fullName +
        '"?'
        : 'Are you sure to remove the ' +
        adminName +
        ' permission of "' +
        rowdata?.fullName +
        '"?',
      'Admin Role Access Confirmation'
    );
  };

  const handleActiveCheckboxChange = (data) => {
    let { rowdata, isActive } = data;
    confirmDialog(
      () => store.dispatch(Action.updateUserDetails({ ...rowdata, isActive })),
      `Are you sure you want to change the status of "${rowdata?.fullName}" to ${isActive === 'I' ? 'inactive' : 'active'
      }?`
    );
  };

  useEffect(() => {
    refresh();
  }, [shouldRefresh]);

  useEffect(() => {
    store.dispatch({
      type: UserTypes.APP_SWITCHED,
      payload: AppConfig.HEADER_MENU_TYPE.NONE
    });
    store.dispatch(Action.fetchApplicationList());
    store.dispatch(Action.fetchTenantList());
  }, []);

  const handleUserAccessManagement = (data) => {
    userData.current = data;
    setShowUserAccessManagementDialog(true);
  };

  const handleUserAccesssManagementClose = (data, isEdit) => {
    setShowUserAccessManagementDialog(false);
    if (data) {
      store.dispatch(
        isEdit
          ? Action.updateUserDetails(data)
          : Action.createNewUserAccess(data)
      );
    }
  };
  useEffect(() => {
    store.dispatch(getAllBrokers());
    store.dispatch(fetchBrokerCompanyMapping());
  }, []);

  const xs_col_px_width = 50; // extra small column width
  const s_col_px_width = 70; // small column width
  const m_col_px_width = 100; // medium column width
  const l_col_px_width = 120; // large column width
  const xl_col_px_width = 150; // extra large column width
  const xxl_col_px_width = 250; // extra extra large column width
  
  return (
    <>
      <FoMaterialTable
        title=""
        tableRef={tableRef}
        columns={[
          {
            title: 'First', field: 'firstName', editable: 'never',
            cellStyle: {
              width: m_col_px_width,
              maxWidth: m_col_px_width,
              overflowWrap: 'break-word',
            },
            headerStyle: {
              width: m_col_px_width,
              maxWidth: m_col_px_width
            }
          },
          {
            title: 'Last', field: 'lastName', editable: 'never',
            cellStyle: {
              width: m_col_px_width,
              maxWidth: m_col_px_width,
              overflowWrap: 'break-word'
            },
            headerStyle: {
              width: m_col_px_width,
              maxWidth: m_col_px_width
            }
          },
          {
            title: 'Username', field: 'username', editable: 'never', 
            cellStyle: {
              width: xl_col_px_width,
              maxWidth: xl_col_px_width,
              overflowWrap: 'break-word'
            },
            headerStyle: {
              width: xl_col_px_width,
              maxWidth: xl_col_px_width
            }
          },
          {
            title: 'Email', field: 'email', editable: 'never', 
            cellStyle: {
              width: xxl_col_px_width,
              maxWidth: xxl_col_px_width,
              overflowWrap: 'normal'
            },
            headerStyle: {
              width: l_col_px_width,
              maxWidth: l_col_px_width
            }
          },
          {
            title: 'Broker', field: 'brokerName', editable: 'never', 
            cellStyle: {
              textAlign: 'start',
              overflowWrap: 'normal',
              width: m_col_px_width,
              maxWidth: m_col_px_width
            },
            headerStyle: {
              width: m_col_px_width,
              maxWidth: m_col_px_width
            }
          },
          {
            title: 'Apps', field: 'appCount', editable: 'never', 
            cellStyle: {
              textAlign: 'center',
              width: xs_col_px_width,
              maxWidth: xs_col_px_width
            },
            headerStyle: {
              textAlign: 'center',
              width: xs_col_px_width,
              maxWidth: xs_col_px_width
            }
          },
          {
            title: 'Active',
            field: 'isActive',
            type: 'boolean',
            cellStyle: {
              textAlign: 'start',
              width: xs_col_px_width,
              maxWidth: xs_col_px_width
            },
            headerStyle: {
              textAlign: 'center',
              width: xs_col_px_width,
              maxWidth: xs_col_px_width
            },
            render: (rowdata) => (
              <Checkbox
                checked={rowdata.isActive === 'A'}
                onChange={() =>
                  handleActiveCheckboxChange({
                    rowdata: rowdata,
                    isActive: rowdata.isActive === 'A' ? 'I' : 'A'
                  })
                }
                inputProps={{ 'aria-label': 'fo active checkbox' }}
                id={'input-adminCheckbox-' + rowdata.id}
                color="primary"
              />
            )
          },
          {
            title: 'System Admin',
            field: 'systemAdmin',
            type: 'boolean',
            cellStyle: {
              textAlign: 'center',
              verticalAlign: 'middle',
              width: xs_col_px_width,
              maxWidth: xs_col_px_width
            },
            headerStyle: {
              textAlign: 'center',
              width: xs_col_px_width,
              maxWidth: xs_col_px_width
            },
            render: (rowdata) => (
              <Checkbox
                disabled={rowdata.external}
                checked={rowdata.external ? false : rowdata.systemAdmin}
                onChange={() =>
                  handleAdminCheckboxChange({
                    rowdata: rowdata,
                    adminCode: 'SYSTEM_ADMIN',
                    adminName: 'System Admin',
                    changedValue: !rowdata.systemAdmin
                  })
                }
                inputProps={{ 'aria-label': 'system admin checkbox' }}
                id={'input-adminCheckbox-' + 'SYSTEM_ADMIN-' + rowdata.id}
                color="primary"
              />
            )
          },
          {
            title: 'FO Admin',
            field: 'foAdmin',
            type: 'boolean',
            cellStyle: {
              textAlign: 'start',
              width: xs_col_px_width,
              maxWidth: xs_col_px_width
            },
            headerStyle: {
              textAlign: 'center',
              width: xs_col_px_width,
              maxWidth: xs_col_px_width
            },
            render: (rowdata) => (
              <Checkbox
                disabled={rowdata.external}
                checked={rowdata.external ? false : rowdata.foAdmin}
                onChange={() =>
                  handleAdminCheckboxChange({
                    rowdata: rowdata,
                    adminCode: 'FRONT_OFFICE',
                    adminName: 'Front Office Admin',
                    changedValue: !rowdata.foAdmin
                  })
                }
                inputProps={{ 'aria-label': 'fo admin checkbox' }}
                id={'input-adminCheckbox-' + 'FO_ADMIN-' + rowdata.id}
                color="primary"
              />
            )
          },
          {
            title: 'MD Admin',
            field: 'mdAdmin',
            type: 'boolean',
            cellStyle: {
              textAlign: 'start',
              width: xs_col_px_width,
              maxWidth: xs_col_px_width
            },
            headerStyle: {
              textAlign: 'center',
              width: s_col_px_width,
              maxWidth: s_col_px_width
            },
            render: (rowdata) => (
              <Checkbox
                disabled={rowdata.external}
                checked={rowdata.external ? false : rowdata.mdAdmin}
                onChange={() =>
                  handleAdminCheckboxChange({
                    rowdata: rowdata,
                    adminCode: 'MASTER_DATA',
                    adminName: 'Master Data Admin',
                    changedValue: !rowdata.mdAdmin
                  })
                }
                inputProps={{ 'aria-label': 'md admin checkbox' }}
                id={'input-adminCheckbox-' + 'MD_ADMIN-' + rowdata.id}
                color="primary"
              />
            )
          },

          {
            title: 'FO Tenant Admin',
            field: 'tenantAdmin',
            type: 'boolean',
            editable: 'never',
            cellStyle: {
              textAlign: 'center',
              width: s_col_px_width,
              maxWidth: s_col_px_width
            },
            headerStyle: {
              textAlign: 'center',
              width: s_col_px_width,
              maxWidth: s_col_px_width
            },
            render: (rowData) =>
              rowData.tenantAdmin === true ? <CheckIcon /> : <ClearIcon />
          },
          {
            title: 'Enigma Tenant Admin',
            field: 'enigmaTenantAdmin',
            type: 'boolean',
            editable: 'never',
            cellStyle: {
              textAlign: 'center',
              width: s_col_px_width,
              maxWidth: s_col_px_width
            },
            headerStyle: {
              textAlign: 'center',
              width: s_col_px_width,
              maxWidth: s_col_px_width
            },
            render: (rowdata) =>
              rowdata.enigmaTenantAdmin === true ? <CheckIcon /> : <ClearIcon />
          },
          {
            title: 'Plan Optimize Tenant Admin',
            field: 'planOptimizeTenantAdmin',
            type: 'boolean',
            editable: 'never',
            cellStyle: {
              textAlign: 'center',
              width: s_col_px_width,
              maxWidth: s_col_px_width
            },
            headerStyle: {
              textAlign: 'center',
              width: s_col_px_width,
              maxWidth: s_col_px_width
            },
            render: (rowdata) =>
              rowdata.planOptimizeTenantAdmin === true ? (
                <CheckIcon />
              ) : (
                <ClearIcon />
              )
          },
          //HIDE THE OPTION FOR 1.2.1 RELEASE
          {
            title: 'Tenant admin',
            type: 'boolean',
            cellStyle: {
              textAlign: 'start',
              width: s_col_px_width,
              maxWidth: s_col_px_width
            },
            headerStyle: {
              textAlign: 'center',
              width: s_col_px_width,
              maxWidth: s_col_px_width
            },
            render: (rowdata) => (
              <Link
                onClick={() => {
                  setShowGrantTenantAdmin(true);
                  setTenantAdminTarget(rowdata);
                }}
                id={'input-adminCheckbox-TENANT_ADMIN_OPEN-' + rowdata.id}
                color="primary">
                Modify
              </Link>
            )
          }
        ]}
        data={(query) =>
          new Promise((resolve, reject) => {
            let pState = {};

            tableRef.current.state.data.map((data) => {
              pState[data.id] = data.tableData.showDetailPanel;
            });
            setdetailpanelState(pState);

            axios
              .post(AppConfig.IAM_BACKEND_URL + `/user-access/search`, {
                page: query.page,
                size: query.pageSize,
                direction: query.orderDirection
                  ? query.orderDirection.toUpperCase()
                  : Constant.DIRECTION_ASC,
                sortProperty: query.orderBy ? query.orderBy.field : 'username',
                searchTerm: query.search
              })
              .then((m) => {
                let data = m.data;
                data.content.forEach((item) => {
                  if (pState[item.id]) {
                    if (item.networkValues) {
                      item.networkValues.forEach((mi) => {
                        const found = interalDetailPanelState[item.id];
                        if (found) {
                          mi.default_open = found[mi.id];
                        }
                      });
                    }
                    item.tableData = {
                      showDetailPanel: tableRef.current.props.detailPanel
                    };
                  }
                });

                resolve({
                  data: data.content,
                  page: data.number,
                  size: data.size,
                  totalCount: data.totalElements
                });
              })
              .catch((err) => {
                resolve({
                  data: [],
                  page: 0,
                  totalCount: 0
                });
                store.dispatch({
                  type: AppActionTypes.APPSTATE_SET_APP_ERROR,
                  payload: 'Could not retrieve the chart.'
                });
              });
          })
        }
        onChangeRowsPerPage={setPageSize}
        options={{
          actionsColumnIndex: -1,
          search: true,
          pageSizeOptions: Constant.DEFAULT_SEARCH_PAGE_SIZE,
          pageSize,
          debounceInterval: 400,
          actionsCellStyle: { minWidth: '50px' }
        }}
        actions={[
          {
            icon: 'edit',
            tooltip: 'Edit',
            onClick: (event, rowData) => {
              handleUserAccessManagement(rowData);
            }
          },
          {
            icon: 'refresh',
            tooltip: 'Refresh Data',
            onClick: () => {
              refresh();
            },
            isFreeAction: true
          },
          {
            icon: SyncAltIcon,
            tooltip: 'Re-sync admin users with tableau',
            onClick: () => {
              confirmDialog(
                () => store.dispatch(Action.syncAdminsWithTableau()),
                'Do you really want to sync ALL System and FO admin users with tableau server?'
              );
            },
            isFreeAction: true
          },
          {
            icon: ExitToAppIcon,
            tooltip: 'Re-sync external users with tableau',
            onClick: () => {
              confirmDialog(
                () => store.dispatch(Action.syncExternalUsersWithTableau()),
                'Do you really want to sync ALL external users with tableau server?'
              );
            },
            isFreeAction: true
          },
          {
            icon: 'add',
            tooltip: 'Create New Access',
            onClick: () => {
              handleUserAccessManagement({});
            },
            isFreeAction: true
          }
        ]}
        components={{ OverlayLoading: () => <div /> }}
        onRowClick={(event, rowData, togglePanel) => {
          if (!event.target.id.startsWith('input-adminCheckbox')) togglePanel();
        }}
        detailPanel={({ rowData }) => {
          return (
            <>
              <Box m={2}>
                <UserPermissionDetailView
                  item={rowData}
                  appList={appList}
                  tenantList={tenantList}
                />
              </Box>
            </>
          );
        }}
      />
      <GrantTenantAdminDialog
        open={showGrantTenantAdmin}
        onClose={() => {
          setShowGrantTenantAdmin(false);
          setTenantAdminTarget(undefined);
        }}
        target={tenantAdminTarget}
      />
      <UserAccessManagementDialog
        open={showUserAccessManagementDialog}
        onClose={handleUserAccesssManagementClose}
        target={userData.current}
      />
    </>
  );
};

export default UserPermissionTable;
