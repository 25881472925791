import { withRouter } from 'react-router-dom';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import axios from 'axios';
import { createStructuredSelector } from 'reselect';
import debounce from 'lodash.debounce';
import 'common/report.css';
import getUrlTicket from 'utils/getUrlTicket';
import { AppConfig } from 'AppConfig';
import * as AppActionTypes from 'store/actions/appstate';
import { catchTableauReqCancel } from 'utils/catchTableauReqCancel';
import { store } from 'store/store';
import { TableauViz} from '@tableau/embedding-api';
/*
  The process of workbook assumes that one workbook has multiple dashborads and
  all filters inside one dashborad have to have their unique name, so users could
  operate on dashboard as atomic unit.
*/

// fo-initial proxy will return 200 even if fo-initial request to tableau server returns 503 
const STRING_503_RESPONSE = "503 Service Temporarily Unavailable";

const is503Error = (data) =>{
  return data.includes(STRING_503_RESPONSE);
};

let AD_HOC_TAB_OPEN = false;
let JUST_ENTER = true;
class Report extends PureComponent {
  state = {
    currentDashboard: null,
    dashboards: null,
    insightUrl: null,
    leftPanelOpen: false,
    showInsight: false,
    vizWidth: 0,
    workbook: null,
    workbookTitle: '',
    viz: null,
    reportUrl: null
  };

  constructor(props) {
    super(props);
    this.vizContainerDiv = React.createRef();
  }

  handleDashboardclick = debounce((event) => {
    if (!this.state.currentDashboard || event !== this.state.currentDashboard) {
      this.state.workbook.activateSheetAsync(event).then((dashboard) => {
        this.setState({
          ...this.state,
          currentDashboard: dashboard.$0.name
        });
      });
    }
  }, AppConfig.DEBOUNCE_WAIT_TIME);

  calculateVizWidth = () => {
    let frameWidthPercentage = 96;
    var mediaDesktopSmall = window.matchMedia('(max-width: 1600px)');

    if (mediaDesktopSmall.matches) {
      frameWidthPercentage = 93.5;
    }
    if (
      this.props.location &&
      this.props.location.state &&
      this.props.location.state.state &&
      this.props.category === AppConfig.EXPLORE_CAROUSEL
    ) {
      frameWidthPercentage = frameWidthPercentage - 6;
    }

    if (this.state.leftPanelOpen === true) {
      if (mediaDesktopSmall.matches) {
        frameWidthPercentage = frameWidthPercentage - 20;
      } else {
        frameWidthPercentage = frameWidthPercentage - 16;
      }
    }
    let frameWidth =
      (frameWidthPercentage *
        (window.innerWidth ||
          document.documentElement.clientWidth ||
          document.body.clientWidth)) /
      100;

    return Math.trunc(frameWidth);
  };

  /**
   * Users own customized workbook named as [originalName]_, first time we will use the name
   * to same a copy under tableau project "Customer Reports", after that, the save operation
   * will just update 'last' view of the workbook
   */
  handleSaveClick = () => {
    if ( this.state.workbook ) {
      if (this.state?.workbook?.getName().endsWith('_')) {
        this.state.workbook.removeCustomViewAsync('last').then(
          (res) => {
            this.state.workbook.rememberCustomViewAsync('last').then(
              (res) => { },
              (err) => {
                console.log("Remember 'last' view failure." + err);
              }
            );
          },
          (err) => {
            console.error("Remove 'last' view failure." + err);
            this.state.workbook.rememberCustomViewAsync('last').then(
              (res) => {
                this.state.workbook.setActiveCustomViewAsDefaultAsync();
              },
              (err) => {
                console.log("Remember 'last' view failure." + err);
              }
            );
          }
        );
      } else {
        axios
          .post(AppConfig.FO_INITIAL_URL + '/init/wbcopy', {
            workbookName: this.state.workbook.getName(),
            targetProject: 'Custom Reports',
            targetWorkbookName: this.state.workbook.getName() + '_',
            reptSource: this.props.reportSource
          })
          .then((res) => { })
          .catch((err) => {
            console.log('wb copy failure,' + err);
            store.dispatch({
              type: AppActionTypes.APPSTATE_SET_APP_ERROR,
              payload: 'Save Failed'
            });
          });
      }
    }
  };

  openTableauAdHoc = (props) => {
    getUrlTicket(props.reportSource)
      .then((res) => {
        if (res) {
          if (res.indexOf('-1') > 0) {
            console.log('could not find content,' + res);
            store.dispatch({
              type: AppActionTypes.APPSTATE_SET_APP_ERROR,
              payload: 'Could not find content.'
            });
            props.history.goBack();
          }
          res.endsWith(props.site)
            ? window.open(res, '_tableau')
            : window.open(res);
        }
      })
      .catch((error) => {
        catchTableauReqCancel(error, 'Server refused Ad-Hoc operation.');
      });
  };

  createTableau = debounce((event) => {
    let frameWidth = this.calculateVizWidth();

    if (
      this.props?.reportSource &&
      this.props.reportSource.indexOf('trusted') > 0
    ) {
      getUrlTicket(this.props.reportSource)
        .then((res) => {
          if (res) {
            if (res === -1) {
              console.log('could not find content,' + res);
              store.dispatch({
                type: AppActionTypes.APPSTATE_SET_APP_ERROR,
                payload: 'Could not find content.'
              });
              return;
            } else if (is503Error(res)) {
              store.dispatch({
                type: AppActionTypes.APPSTATE_SET_APP_ERROR,
                payload: 'Tableau Service Unavailable.'
              });
              return;
            }
            this.setState({ ...this.state, reportUrl: res });
            this.state.viz && this.state.viz.dispose();
            this.initViz(
              res,
              this.vizContainer,
              frameWidth,
              AppConfig.TABLEAU_REPORT_HEIGHT
            )
              .then(
                (r) => {
                  let allSheets = r.workbook.getPublishedSheetsInfo();
                  let myDashboards = null;
                  let hiding = null;
                  if (allSheets) {
                    myDashboards = allSheets.filter(
                      (s) => s.getSheetType() === 'dashboard'
                    );
                    hiding = allSheets.filter(
                      (d) => d.$0.name.toUpperCase().indexOf('HIDING') !== -1
                    );
                  }

                  if (Array.isArray(hiding) && hiding.length === 1) {
                    let hidingDashboards = hiding[0].$0.name
                      .split(':')[1]
                      .split(',');
                    myDashboards = myDashboards.filter(
                      (m) =>
                        hidingDashboards.indexOf(m.$0.name) === -1 &&
                        m.$0.name.toUpperCase().indexOf('HIDING') === -1
                    );
                  }
                  if (r?.workbook) {
                    r.workbook.getParametersAsync().then(
                      (p) => {
                        this.setState({
                          ...this.state,
                          currentDashboard:
                            Array.isArray(myDashboards) && myDashboards.length
                              ? myDashboards[0]
                              : null,
                          dashboards: myDashboards,
                          workbookTitle: r.workbook.getName(),
                          workbook: r.workbook,
                          vizWidth: frameWidth
                        });
                      },
                      (e) => console.log('get wb paramters:' + e)
                    );
                  }
                },
                (err) => {
                  console.log('Cannot get viz ', err);
                }
              )
              .catch((err) => {
                console.log('load workbook err:' + err);
              });
          }
        })
        .catch((error) => {
          catchTableauReqCancel(error, 'No Content Available');
        });
    }
  }, AppConfig.DEBOUNCE_WAIT_TIME);

  initViz(vizUrl, vizContainer, vizWidth, vizHeight) {
    const ths = this;
    return new Promise(function (resolve, reject) {
      let viz = new TableauViz();
      viz.src = vizUrl + '?:embed=yes';
      viz.toolbar = 'top';
      viz.debug=false;
      viz.height=vizHeight;
      viz.width = vizWidth;
      document.getElementById('tableauViz').appendChild(viz);
      ths.setState({
        ...ths.state,
        viz: viz
      });
    });
  }

  componentWillReceiveProps(nextProps) {
    if (
      nextProps?.reportSource?.endsWith(this.props.site) &&
      AD_HOC_TAB_OPEN === false &&
      JUST_ENTER === true
    ) {
      AD_HOC_TAB_OPEN = true;
      this.openTableauAdHoc(nextProps);
    }
  }

  componentWillUnmount() {
    AD_HOC_TAB_OPEN = false;
    JUST_ENTER = true;
  }

  componentDidMount() {
    this.createTableau();
  }

  render() {
    JUST_ENTER = false;
    if (
      this.props?.reportSource?.endsWith(this.props.site) &&
      AD_HOC_TAB_OPEN === false
    ) {
      AD_HOC_TAB_OPEN = true;
      this.openTableauAdHoc(this.props);
    }

    if (!this.props?.reportSource?.endsWith(this.props.site)) {
      let frameWidth = this.calculateVizWidth();

      if (this.state.workbook && frameWidth !== this.state.vizWidth) {
        this.state.workbook
          .getActiveSheet()
          .changeSizeAsync({
            behavior: 'EXACTLY',
            maxSize: {
              height: AppConfig.TABLEAU_REPORT_HEIGHT,
              width: frameWidth
            }
          })
          .then(
            this.state.workbook
              .getViz()
              .setFrameSize(frameWidth, AppConfig.TABLEAU_REPORT_HEIGHT)
          );
        this.setState({ ...this.state, vizWidth: frameWidth });
      }
    }
    return (
      <>
        <div className="d-flex w-100">
          <div className="flex-fill reports-content">
            <div className="iframe-full-width" ref={this.vizContainerDiv}>
                 <div id="tableauViz"></div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

const mapStateToPropsSelector = createStructuredSelector({
  permissions: (state) => state.user.permissions,
  traits: (state) => state.fo.inspection
});

export default connect(mapStateToPropsSelector)(withRouter(Report));
