import React from 'react';
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import 'core-js';
import { Provider } from 'react-redux';
import ReactDOM from 'react-dom';
import './index.css';
import '@fortawesome/fontawesome-free/css/all.min.css';
import 'bootstrap-css-only/css/bootstrap.min.css';
import 'mdbreact/dist/css/mdb.css';
import './assets/font/open-sans/OpenSans-Regular.ttf';
import './assets/font/open-sans/OpenSans-Semibold.ttf';
import './assets/font/open-sans/OpenSans-Bold.ttf';
import { store } from './store/store';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { createTheme, ThemeProvider } from '@material-ui/core/styles';
import AppDynamicsInitializer from 'features/appdynamics/AppDynamicsInitializer';

const theme = createTheme({
  palette: {
    tertiary: '#C0C0C0'
  },
  overrides: {
    MuiAutocomplete: {
      option: {
        '&[aria-selected="true"]': {
          backgroundColor: '#7986CB',
          color: 'white'
        },
        '&[data-focus="true"]': {
          color: 'black'
        }
      }
    },
    MuiMenuItem: {
      root: {
        '&$selected': {
          backgroundColor: '#7986CB',
          color: 'white'
        },
        '&:hover': {
          color: 'black'
        }
      }
    },
    MuiCheckbox: {
      colorSecondary: {
        '&$checked': {
          color: '#3F51B5'
        }
      }
    },
    MuiSwitch: {
      colorSecondary: {
        '&$checked': {
          color: '#3F51B5'
        }
      },
      track: {
        '$checked$checked + &': {
          opacity: 0.7,
          backgroundColor: '#3F51B5'
        }
      }
    },
    MuiTab: {
      root: {
        minWidth: 0,
        '@media (min-width: 0px)': {
          minWidth: 0
        },
        textTransform: 'none',
        fontSize: 14
      }
    }
  }
});

ReactDOM.render(
  <Provider store={store}>
    <ThemeProvider theme={theme}>
      <AppDynamicsInitializer/>
      <App />
    </ThemeProvider>
  </Provider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
