import ClientPortalNavigator from 'features/clientPortal/clientPortalNavigator';
import FrontOffice from 'features/frontoffice/index';
import BstBars from 'features/landing/navigation/top/bstBars';
import LookupTableNav from 'features/masterData/components/lookup/lookupTableNav';
import Master from 'features/masterData/master';
import { Fragment, Suspense } from 'react';
import { connect, useSelector } from 'react-redux';
import { Redirect, Route, Switch } from 'react-router-dom';
import { createStructuredSelector } from 'reselect';
import 'routes/appView.css';
import { AppConfig } from '../AppConfig';
import AdminNavigator from '../features/admin/navigation/adminNavigator';
import TenantAdminNavigator from '../features/admin/navigation/tenantAdminNavigator';
import TenantManagementNavigator from '../features/admin/navigation/tenantManagementNavigator';
import WhiteLabelNavigator from '../features/admin/navigation/whiteLabelingNavigator';
import TenantRefreshDataNavigator from '../features/tenantRefresh/tenantRefreshDataAdminNavigator';
import RouteConfig from './RouteConfig';

const AppView = ({ currentApp }) => {
  const showBstBarTenant = useSelector((state) => state.appState.showBstBarTenant);

  return (
    <div>
      <Suspense fallback={<h1>loading...</h1>}>
        <BstBars key={currentApp + showBstBarTenant} showBstBarTenant={showBstBarTenant} />
        <main className="site-main" style={{ marginTop: 70 }}>
          <Switch>
            <Route
              path={RouteConfig.frontOffice.path}
              render={({ location }) => (
                <Fragment>
                  <FrontOffice location={location} />
                  <Switch>
                    <Route exact path={RouteConfig.frontOffice.path + AppConfig.FRONT_OFFICE_SUB_URL.STANDARD} />
                    <Route exact path={RouteConfig.frontOffice.path + AppConfig.FRONT_OFFICE_SUB_URL.MY_REPORT} />
                    <Route exact path={RouteConfig.frontOffice.path + AppConfig.FRONT_OFFICE_SUB_URL.MY_FAVORITE} />
                    <Route exact path={RouteConfig.frontOffice.path + AppConfig.FRONT_OFFICE_SUB_URL.EXPLORER} />
                    <Route exact path={RouteConfig.frontOffice.path + AppConfig.FRONT_OFFICE_SUB_URL.EXCLUSIVE} />
                    <Route exact path={RouteConfig.frontOffice.path + AppConfig.FRONT_OFFICE_SUB_URL.MY_CUSTOM} />
                    <Route exact path={RouteConfig.frontOffice.path + AppConfig.FRONT_OFFICE_SUB_URL.REPORT_MANAGEMENT} />
                    <Route exact path={RouteConfig.frontOffice.path + AppConfig.FRONT_OFFICE_SUB_URL.EXISTING_REPORTS} />
                    <Redirect exact from={RouteConfig.frontOffice.path + '/*'} to={RouteConfig.frontOffice.path} />
                  </Switch>
                </Fragment>
              )}
            />
            <Route path={RouteConfig.newHome.path} exact component={RouteConfig.newHome.component} />
            <Route
              path={RouteConfig.master.path}
              render={() => (
                <Fragment>
                  <Master />
                  <Switch>
                    <Route path={AppConfig.MASTER_DATA_SUB_URL.MASTER_TABLE} />
                    <Route path={AppConfig.MASTER_DATA_SUB_URL.ET_SCHEMA} />
                    <Route path={AppConfig.MASTER_DATA_SUB_URL.DENTAL_PLAN} />
                    <Route path={AppConfig.MASTER_DATA_SUB_URL.VISION_PLAN} />
                    <Route path={AppConfig.MASTER_DATA_SUB_URL.PLAN_EXPENSE} />
                    <Route path={AppConfig.MASTER_DATA_SUB_URL.PLAN_RATES} />
                    <Route path={AppConfig.MASTER_DATA_SUB_URL.DIVISION} />
                    <Route path={AppConfig.MASTER_DATA_SUB_URL.AUDITING} />
                    <Route path={AppConfig.MASTER_DATA_SUB_URL.VALIDATOIN} />
                    <Route path={AppConfig.MASTER_DATA_SUB_URL.EMPLOYEE_CLASS} />
                    <Route path={AppConfig.MASTER_DATA_SUB_URL.ANNUAL_HEALTH_PLAN} />
                    <Route path={AppConfig.MASTER_DATA_SUB_URL.PLAN_DESIGN_DATA} />
                    <Route path={AppConfig.MASTER_DATA_SUB_URL.DISCRETIONARY_BENEFITS} />
                  </Switch>
                </Fragment>
              )}
            />
            <Route
              path={RouteConfig.lookup.path}
              render={() => (
                <Fragment>
                  <LookupTableNav />
                  <Switch>
                    <Route exact path={RouteConfig.lookup.path + AppConfig.LOOKUP_TABLES_SUB_URL.LOOKUP} />
                    <Route exact path={RouteConfig.lookup.path + AppConfig.LOOKUP_TABLES_SUB_URL.ELECTION_TIER_LIST} />
                    <Redirect exact from={RouteConfig.lookup.path + '/*'} to={RouteConfig.lookup.path + AppConfig.LOOKUP_TABLES_SUB_URL.LOOKUP} />
                  </Switch>
                </Fragment>
              )}
            />
            <Route path={RouteConfig.externalIframe.path} component={RouteConfig.externalIframe.component} />
            <Route path={RouteConfig.logout.path} exact component={RouteConfig.logout.component} />
            <Route path={RouteConfig.userProfile.path} exact component={RouteConfig.userProfile.component} />
            <Route path={RouteConfig.clientTable.path} exact component={RouteConfig.clientTable.component} />
            <Route
              path={RouteConfig.tenantRefresh.path}
              render={({ location }) => (
                <Fragment>
                  <TenantRefreshDataNavigator location={location} />
                  <Switch>
                    <Route exact path={RouteConfig.tenantRefresh.path} />
                    <Redirect exact from={RouteConfig.tenantRefresh.path + '/*'} to={RouteConfig.tenantRefresh.path} />
                  </Switch>
                </Fragment>
              )}
            />
            <Route
              path={RouteConfig.tenantAdmin.path}
              render={() => (
                <Fragment>
                  <TenantAdminNavigator />
                  <Switch>
                    <Route exact path={RouteConfig.tenantAdmin.path + AppConfig.TENANT_ADMIN_SUB_URL.TENANT_USER_ACCESS} />
                    <Route epath={RouteConfig.tenantAdmin.path + AppConfig.TENANT_ADMIN_SUB_URL.TENANT_USER_ACCESS} />
                    <Redirect exact from={RouteConfig.tenantAdmin.path + '/*'} to={RouteConfig.tenantAdmin.path} />
                  </Switch>
                </Fragment>
              )}
            />
            <Route
              path={RouteConfig.tenantManagement.path}
              render={() => (
                <Fragment>
                  <TenantManagementNavigator />
                  <Switch>
                    <Route exact path={RouteConfig.tenantManagement.path + AppConfig.TENANT_MANAGEMENT_SUB_URL.TENANT} />
                    <Route exact path={RouteConfig.tenantManagement.path + AppConfig.TENANT_MANAGEMENT_SUB_URL.BROKER} />
                    <Route exact path={RouteConfig.tenantManagement.path + AppConfig.TENANT_MANAGEMENT_SUB_URL.MD_RENEWAL_MGMT} />
                    <Redirect exact from={RouteConfig.tenantManagement.path + '/*'} to={RouteConfig.tenantManagement.path + AppConfig.TENANT_MANAGEMENT_SUB_URL.TENANT} />
                  </Switch>
                </Fragment>
              )}
            />
            <Route
              path={RouteConfig.clientPortal.path + AppConfig.CLIENT_PORTAL_SUB_URL.ANNUAL_PLAN_RENEWAL}
              render={() => (
                <Fragment>
                  <ClientPortalNavigator />
                  <Switch>
                    <Route exact path={RouteConfig.clientPortal.path + AppConfig.CLIENT_PORTAL_SUB_URL.ANNUAL_PLAN_RENEWAL} />
                    <Redirect exact from={RouteConfig.clientPortal.path} to={RouteConfig.clientPortal.path + AppConfig.CLIENT_PORTAL_SUB_URL.ANNUAL_PLAN_RENEWAL} />
                  </Switch>
                </Fragment>
              )}
            />
            <Route path={RouteConfig.clientPortalQuestionary.path} exact component={RouteConfig.clientPortalQuestionary.component} />
            <Route
              path={RouteConfig.whiteLabeling.path}
              render={() => (
                <Fragment>
                  <WhiteLabelNavigator />
                  <Switch>
                    <Route exact path={RouteConfig.whiteLabeling.path + AppConfig.WHITE_LABEL_SUB_URL.WHITE_LABEL} />
                    <Route exact path={RouteConfig.whiteLabeling.path + AppConfig.WHITE_LABEL_SUB_URL.VENDOR_LABELING} />
                    <Route exact path={RouteConfig.whiteLabeling.path + AppConfig.WHITE_LABEL_SUB_URL.VENDOR_LABELING_BROKER_VIEW} />
                    <Route exact path={RouteConfig.whiteLabeling.path + AppConfig.WHITE_LABEL_SUB_URL.TAG_MANAGEMENT} />
                    <Redirect exact from={RouteConfig.whiteLabeling.path + '/*'} to={RouteConfig.whiteLabeling.path + AppConfig.WHITE_LABEL_SUB_URL.WHITE_LABEL} />
                  </Switch>
                </Fragment>
              )}
            />
            <Route
              path={RouteConfig.adminPage.path}
              render={({ location }) => (
                <Fragment>
                  <AdminNavigator location={location} />
                  <Switch>
                    <Route exact path={RouteConfig.adminPage.path} />
                    <Route exact path={RouteConfig.adminPage.path + AppConfig.ADMIN_SUB_URL.ROLE_MANAGEMENT} />
                    <Route exact path={RouteConfig.adminPage.path + AppConfig.ADMIN_SUB_URL.USER_GROUP} />
                    <Redirect exact from={RouteConfig.adminPage.path + '/*'} to={RouteConfig.adminPage.path} />
                  </Switch>
                </Fragment>
              )}
            />
            <Route path={RouteConfig.reportDefaultSetting.path} exact component={RouteConfig.reportDefaultSetting.component} />
            <Redirect from={'*'} to={RouteConfig.newHome.path} />
          </Switch>
        </main>
      </Suspense>
    </div>
  );
};
const mapStateToPropsSelector = createStructuredSelector({
  appError: (state) => state.appState.appError
});
export default connect(mapStateToPropsSelector)(AppView);
