import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { checkAuth } from 'routes/checkAuth';
import { AppConfig } from 'AppConfig';
import RouteConfig from 'routes/RouteConfig';
import { useHistory } from 'react-router-dom';
import { fetchData } from 'features/user/userActions';

const NewHome = () => {
  const history = useHistory();
  const currentApp = useSelector((state) => state.user.currentApp);
  const currentTenantCd = useSelector((state) => state.user.currentTenantCd);

  const loadProfile = async () => {
    if (checkAuth()) {
      await fetchData(currentApp, currentTenantCd);

      switch (currentApp) {
        case AppConfig.APP_CODE_FO:
          history.push(RouteConfig.frontOffice.path);
          break;
        case AppConfig.APP_CODE_MASTER_DATA:
          history.push(RouteConfig.master.path);
          break;
        default:
          history.push(RouteConfig.frontOffice.path);
      }
    }
  };

  useEffect(() => {
    loadProfile();
  }, []);

  return <></>;
};

export default NewHome;
