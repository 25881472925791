import { AppConfig } from '../../AppConfig';
import { useEffect } from "react";

const AppDynamicsInitializer = () => {
  useEffect(() => {
    if (!AppConfig.APPDYNAMCS_KEY) {
      console.log("AppDynamics key is not defined. Skipping AppDynamics initialization.");
      return;
    }

    // Set the adrum-start-time
    window["adrum-start-time"] = new Date().getTime();

    // AppDynamics configuration
    const config = window["adrum-config"] || (window["adrum-config"] = {});
    config.appKey = AppConfig.APPDYNAMCS_KEY;
    config.adrumExtUrlHttp = "http://cdn.appdynamics.com";
    config.adrumExtUrlHttps = "https://cdn.appdynamics.com";
    config.beaconUrlHttp = "http://pdx-col.eum-appdynamics.com";
    config.beaconUrlHttps = "https://pdx-col.eum-appdynamics.com";
    config.useHTTPSAlways = true;
    config.resTiming = { bufSize: 200, clearResTimingOnBeaconSend: true };
    config.maxUrlLength = 512;

    // Create script element for AppDynamics
    const script = document.createElement("script");
    script.src = "//cdn.appdynamics.com/adrum/adrum-24.4.0.4454.js";
    script.charset = "UTF-8";
    script.type = "text/javascript";
    script.async = true; // Load script asynchronously
    document.head.appendChild(script);

    // Cleanup script on component unmount
    return () => {
      document.head.removeChild(script);
    };
  }, []);

  return null;
};

export default AppDynamicsInitializer;