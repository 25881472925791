import MaterialTable from '@material-table/core';
import { AppConfig } from 'AppConfig';
import axios from 'axios';
import { useEffect, useState } from 'react';
import CustomToolbar from './components/CustomToolBar';
import RefreshDashboardColumns from './tenantRefreshDashboardColumn';
import * as AppActionTypes from 'store/actions/appstate';
import { store } from 'store/store';

const TenantRefreshDashboard = () => {
  const [reports, setReports] = useState([]);
  const [displayData, setDisplayData] = useState([]);
  const [loading, setLoading] = useState(false);
  const ALL_COLUMNS_TYPE = 'all_columns';

  useEffect(() => {
    store.dispatch({
      type: AppActionTypes.SHOW_BST_BAR_TENANT,
      payload: false
    });
    return () => {
      store.dispatch({
        type: AppActionTypes.SHOW_BST_BAR_TENANT,
        payload: true
      });
    };
  }, []);

  useEffect(() => {
    const fetchTenantRefreshReports = async () => {
      setLoading(true);
      try {
        const { data } = await axios.get(`${AppConfig.FO_INITIAL_URL}/tenant-refresh/reports`);
        setReports(data);
        setDisplayData(data); // Initialize displayData with the fetched data
      } catch (error) {
        console.error('Error fetching tenant refresh reports:', error);
      } finally {
        setLoading(false);
      }
    };
    fetchTenantRefreshReports();
  }, []);

  const handleFilterChange = (columnId, value) => {
    const filteredData = reports.filter((report) => {
      if (value === '') return true;
      return report.refreshStatus === value;
    });
    setDisplayData(filteredData);
  };

  return (
    <MaterialTable
      title=""
      columns={RefreshDashboardColumns()}
      data={displayData} // Use displayData as the result to show
      isLoading={loading}
      options={{
        maxColumnSort: ALL_COLUMNS_TYPE,
        search: true,
        pageSize: 10,
        pageSizeOptions: [10, 20, 50],
        toolbar: true, // Enable toolbar for search and filter
        headerStyle: { backgroundColor: AppConfig.THEME?.PALETTE?.BACKGROUND_DEFAULT || '#ffffff' }
      }}
      // components={{
      //   Toolbar: (props) => <CustomToolbar {...props} onFilterChange={handleFilterChange} />
      // }}
    />
  );
};

export default TenantRefreshDashboard;
