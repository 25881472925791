import React, { useRef } from 'react';
import { useSelector } from 'react-redux';
import { IconButton, Grid, Popper, Container, Divider, Box } from '@material-ui/core';
import { useClickAwayListener } from 'common/useClickAwayListener';
import AppsIcon from '@material-ui/icons/Apps';
import AssessmentIcon from '@material-ui/icons/Assessment';
import AssignmentIcon from '@material-ui/icons/Assignment';
import SupervisorAccountIcon from '@material-ui/icons/SupervisorAccount';
import HelpIcon from '@material-ui/icons/Help';
import CoPresentIcon from '@mui/icons-material/CoPresent';
import CameraIcon from '@material-ui/icons/Camera';
import RouteConfig from 'routes/RouteConfig';
import { AppConfig } from 'AppConfig';
import { AppButton, AppContainer } from 'common/BstAppButton';
import { useHistory } from 'react-router-dom';
import { hasPermission, hasTenantDataRefreshPermission } from 'utils/common';
import SettingsInputCompositeIcon from '@material-ui/icons/SettingsInputComposite';
import { store } from 'store/store';
import * as ReportActions from 'features/frontoffice/actions/actions';
import AccountTreeIcon from '@material-ui/icons/AccountTree';
import BusinessIcon from '@material-ui/icons/Business';
import * as UserTypes from 'features/user/userTypes';
import FindInPageIcon from '@material-ui/icons/FindInPage';
import MultilineChartIcon from '@material-ui/icons/MultilineChart';
import SettingsApplicationsIcon from '@material-ui/icons/SettingsApplications';
import ScreenLockLandscapeIcon from '@material-ui/icons/ScreenLockLandscape';
import RefreshRoundedIcon from '@material-ui/icons/RefreshRounded';
import BlurLinearIcon from '@mui/icons-material/BlurLinear';
import { trackingTypes, trackingActions, trackClick } from 'utils/tracking';
import { AlertDialog } from 'features/ad-hoc/alertDialog';
import { OPEN_ALERT_DIALOG } from 'store/actions/appstate';
import LocalStore from 'utils/LocalStore';

const AppNavigator = () => {
  const LOOKUP_URL = RouteConfig.lookup.path + AppConfig.LOOKUP_TABLES_SUB_URL.LOOKUP;
  const REPORT_DEFAULT_SETTING = RouteConfig.reportDefaultSetting.path;

  const [anchorEl, setAnchorEl] = React.useState(null);

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  const apps = useSelector((state) => state.user.apps);
  const currentApp = useSelector((state) => state.user.currentApp);
  const permissions = useSelector((state) => state.user.permissions);
  const tenantApps = useSelector((state) => state.user.tenantApps);
  const hasEnigmaAccess = useSelector((state) => state.user.hasEnigmaAccess);
  const userObj = useSelector((state) => state.user);

  const history = useHistory();
  const menuIconRef = useRef();

  const handleClick = (event) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  useClickAwayListener(menuIconRef, handleClose);

  const handleLinkClick = (url, targetApp, dispatchType) => {
    if (targetApp !== currentApp) {
      store.dispatch({
        type: dispatchType ? dispatchType : UserTypes.RESET_APPS_TENANT
      });
    }
    setAnchorEl(null);
    history.push(url);
  };

  //todo: move this to somewhere else
  const openTableauAdHoc = () => {
    store.dispatch(ReportActions.navigateToAdhoc());
  };

  const onClickTableauAdhoc = () => {
    store.dispatch({
      type: OPEN_ALERT_DIALOG,
      payload: true
    });
  };

  const handleVaultAccess = () => {
    window.open(AppConfig.VAULT_URL);
  };

  return (
    <div>
      <IconButton onClick={handleClick} ref={menuIconRef}>
        <AppsIcon />
      </IconButton>
      <Popper
        id={id}
        open={open}
        anchorEl={anchorEl}
        style={{
          zIndex: 100000000
        }}>
        <Box
          component={Container}
          boxShadow={3}
          style={{
            background: 'white',
            maxWidth: '420px'
          }}>
          <Grid container>
            <Grid item xs={12}>
              <Grid container>
                {apps?.includes(AppConfig.APP_CODE_FO) && (
                  <Grid item xs={4}>
                    <AppContainer>
                      <AppButton
                        color={tenantApps?.includes(AppConfig.APP_CODE_FO) ? 'primary' : 'tertiary'}
                        onClick={() => {
                          trackClick(trackingTypes.APPLICATION, trackingActions.SWITCH, AppConfig.APP_CODE_FO);
                          handleLinkClick(RouteConfig.frontOffice.path, AppConfig.APP_CODE_FO);
                        }}>
                        <AssessmentIcon />
                        BenInsight
                      </AppButton>
                    </AppContainer>
                  </Grid>
                )}
                {hasEnigmaAccess && (
                  <Grid item xs={4}>
                    <AppContainer>
                      <AppButton
                        color="primary"
                        onClick={() => {
                          trackClick(trackingTypes.APPLICATION, trackingActions.SWITCH, AppConfig.ENIGMA_URL);
                          window.open(AppConfig.ENIGMA_URL, '_enigma');
                        }}>
                        <HelpIcon />
                        Enigma
                      </AppButton>
                    </AppContainer>
                  </Grid>
                )}
                {hasPermission(AppConfig.PERMISSION.FO_AD_HOC, permissions) && (
                  <Grid item xs={4}>
                    <AppContainer>
                      <AppButton
                        color="primary"
                        onClick={() => {
                          trackClick(trackingTypes.APPLICATION, trackingActions.SWITCH, AppConfig.PERMISSION.FO_AD_HOC);
                          onClickTableauAdhoc();
                        }}>
                        <SettingsInputCompositeIcon />
                        Ad Hoc Report
                      </AppButton>
                    </AppContainer>
                  </Grid>
                )}
                {hasPermission(AppConfig.APP_CODE_PLAN_OPTIMIZE, apps) && (
                  <Grid item xs={4}>
                    <AppContainer>
                      <AppButton
                        color="primary"
                        onClick={() => {
                          trackClick(trackingTypes.APPLICATION, trackingActions.SWITCH, AppConfig.PERMISSION.PLAN_OPT_VIEW);
                          window.open(AppConfig.PLAN_OPT_URL, '_blank');
                        }}>
                        <MultilineChartIcon />
                        Plan Optimizer
                      </AppButton>
                    </AppContainer>
                  </Grid>
                )}
                {hasPermission(AppConfig.PERMISSION.VAULT_ACCESS, permissions) && (
                  <Grid item xs={4}>
                    <AppContainer>
                      <AppButton
                        color="primary"
                        onClick={() => {
                          trackClick(trackingTypes.APPLICATION, trackingActions.SWITCH, AppConfig.PERMISSION.VAULT_ACCESS);
                          handleVaultAccess();
                        }}>
                        <ScreenLockLandscapeIcon />
                        Vault
                      </AppButton>
                    </AppContainer>
                  </Grid>
                )}
                {(hasPermission(AppConfig.PERMISSION.PREMIUM_OPTIMIZER_VIEW, permissions) || apps?.includes(AppConfig.APP_CODE_PREMIUM_OPTIMIZER)) && (
                  <Grid item xs={4}>
                    <AppContainer>
                      <AppButton
                        color="primary"
                        onClick={() => {
                          trackClick(trackingTypes.APPLICATION, trackingActions.SWITCH, AppConfig.PERMISSION.PREMIUM_OPTIMIZER_VIEW);
                          // Sol 1: set cookies, only works on the same domain
                          // Cookies.set('deviceToken', LocalStore.getBstDevice());
                          // var premium_optimizer_window = window.open(AppConfig.PREMIUM_OPTIMIZER_URL, '_blank');

                          // Sol 2: needs to work on different domains(sendMessgae does not work)
                          var tokens = LocalStore.getBstDevice().split(':');
                          if (tokens && tokens.length > 0 && tokens[tokens.length - 1] !== '') {
                            window.open(AppConfig.PREMIUM_OPTIMIZER_URL + '/?data=' + encodeURIComponent(tokens[tokens.length - 1]), '_blank');
                          } else {
                            window.open(AppConfig.PREMIUM_OPTIMIZER_URL, '_blank');
                          }
                        }}>
                        <BlurLinearIcon />
                        Premium Optimizer
                      </AppButton>
                    </AppContainer>
                  </Grid>
                )}
                {(hasPermission(AppConfig.PERMISSION.CLIENT_PORTAL, permissions) || apps?.includes(AppConfig.APP_CODE_CLIENT_PORTAL)) && (
                  <Grid item xs={4}>
                    <AppContainer>
                      <AppButton color="primary" onClick={() => handleLinkClick(RouteConfig.clientPortal.path + AppConfig.CLIENT_PORTAL_SUB_URL.ANNUAL_PLAN_RENEWAL,
                         AppConfig.APP_CODE_CLIENT_PORTAL)}>
                        <CoPresentIcon />
                        ClientXchange
                      </AppButton>
                    </AppContainer>
                  </Grid>
                )}
                {hasTenantDataRefreshPermission(userObj) && (
                <Grid item xs={4}>
                  <AppContainer>
                    <AppButton
                      color="primary"
                      onClick={() => {
                        trackClick(trackingTypes.APPLICATION, trackingActions.SWITCH, AppConfig.PERMISSION.FO_COMPANY_MANAGEMENT);
                        handleLinkClick(RouteConfig.tenantRefresh.path, undefined, UserTypes.RESET_APP);
                      }}>
                      <RefreshRoundedIcon />
                      Refresh Status
                    </AppButton>
                  </AppContainer>
                </Grid>
              )}
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Divider />
          </Grid>
          <Grid item xs={12}>
            <Grid container>
              {apps?.includes(AppConfig.APP_CODE_MASTER_DATA) && (
                <Grid item xs={4}>
                  <AppContainer>
                    <AppButton
                      color={tenantApps?.includes(AppConfig.APP_CODE_MASTER_DATA) ? 'primary' : 'tertiary'}
                      onClick={() => {
                        trackClick(trackingTypes.APPLICATION, trackingActions.SWITCH, AppConfig.APP_CODE_MASTER_DATA);
                        handleLinkClick(RouteConfig.master.path + AppConfig.MASTER_DATA_SUB_URL.MASTER_TABLE, AppConfig.APP_CODE_MASTER_DATA);
                      }}>
                      <AssignmentIcon />
                      Master Data
                    </AppButton>
                  </AppContainer>
                </Grid>
              )}
              {hasPermission(AppConfig.PERMISSION.LOGO_MANAGEMENT, permissions) && (
                <Grid item xs={4}>
                  <AppContainer>
                    <AppButton
                      color="primary"
                      onClick={() => {
                        trackClick(trackingTypes.APPLICATION, trackingActions.SWITCH, AppConfig.PERMISSION.LOGO_MANAGEMENT);
                        handleLinkClick(RouteConfig.whiteLabeling.path + AppConfig.WHITE_LABEL_SUB_URL.WHITE_LABEL, undefined, UserTypes.RESET_APP);
                      }}>
                      <CameraIcon />
                      White Label
                    </AppButton>
                  </AppContainer>
                </Grid>
              )}
              {hasPermission(AppConfig.PERMISSION.USER_PERMISSION_ADMIN, permissions) && (
                <Grid item xs={4}>
                  <AppContainer>
                    <AppButton
                      color="primary"
                      onClick={() => {
                        trackClick(trackingTypes.APPLICATION, trackingActions.SWITCH, AppConfig.PERMISSION.USER_PERMISSION_ADMIN);
                        handleLinkClick(RouteConfig.adminPage.path, undefined, UserTypes.RESET_APP);
                      }}>
                      <SupervisorAccountIcon />
                      User Admin
                    </AppButton>
                  </AppContainer>
                </Grid>
              )}
              {hasPermission(AppConfig.PERMISSION.FO_LOCAL_TENANT_MANAGEMENT, permissions) && (
                <Grid item xs={4}>
                  <AppContainer>
                    <AppButton
                      color="primary"
                      onClick={() => {
                        trackClick(trackingTypes.APPLICATION, trackingActions.SWITCH, AppConfig.PERMISSION.FO_LOCAL_TENANT_MANAGEMENT);
                        handleLinkClick(RouteConfig.tenantAdmin.path);
                      }}>
                      <AccountTreeIcon />
                      Tenant Admin
                    </AppButton>
                  </AppContainer>
                </Grid>
              )}
              {hasPermission(AppConfig.PERMISSION.FO_COMPANY_MANAGEMENT, permissions) && (
                <Grid item xs={4}>
                  <AppContainer>
                    <AppButton
                      color="primary"
                      onClick={() => {
                        trackClick(trackingTypes.APPLICATION, trackingActions.SWITCH, AppConfig.PERMISSION.FO_COMPANY_MANAGEMENT);
                        handleLinkClick(RouteConfig.tenantManagement.path + AppConfig.TENANT_MANAGEMENT_SUB_URL.TENANT, undefined, UserTypes.RESET_APP);
                      }}>
                      <BusinessIcon />
                      Tenant Management
                    </AppButton>
                  </AppContainer>
                </Grid>
              )}
              {hasPermission(AppConfig.PERMISSION.MD_LKP, permissions) && (
                <Grid item xs={4}>
                  <AppContainer>
                    <AppButton
                      color="primary"
                      onClick={() => {
                        trackClick(trackingTypes.APPLICATION, trackingActions.SWITCH, AppConfig.PERMISSION.MD_LKP);
                        handleLinkClick(LOOKUP_URL, AppConfig.APP_CODE_MASTER_DATA);
                      }}>
                      <FindInPageIcon />
                      Lookup
                    </AppButton>
                  </AppContainer>
                </Grid>
              )}
              {hasPermission(AppConfig.PERMISSION.FO_REPORT_MANAGEMENT, permissions) && (
                <Grid item xs={4}>
                  <AppContainer>
                    <AppButton
                      color="primary"
                      onClick={() => {
                        trackClick(trackingTypes.APPLICATION, trackingActions.SWITCH, AppConfig.PERMISSION.FO_REPORT_MANAGEMENT);
                        handleLinkClick(REPORT_DEFAULT_SETTING, undefined, UserTypes.RESET_APP);
                      }}>
                      <SettingsApplicationsIcon />
                      Global Report Configuration
                    </AppButton>
                  </AppContainer>
                </Grid>
              )}
            </Grid>
          </Grid>
        </Box>
      </Popper>
      <AlertDialog handleComfirm={openTableauAdHoc} />
    </div>
  );
};

export default AppNavigator;
