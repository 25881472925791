export const FORMAT_DATE = 'MM/DD/YYYY';
export const MM_DD_YYYY_DATE_REGEX = /^(0[1-9]|1[0-2])\/(0[1-9]|[12][0-9]|3[01])\/\d{4}$/;
export const INVALID_DATE_YEAR_REGEX = /^(0[1-9]|1[0-2])\/(0[1-9]|[12][0-9]|3[01])\/0000$/;
export const DATE_YEAR_REGEX = /^(0[1-9]|1[0-2])\/(0[1-9]|[12][0-9]|3[01])\/((19|20)\d{2})$/;
export const PLAN_EXPENSE_DATA_ONE_TIME_SPECIAL = 'one-time special';
export const DIRECTION_DESC = 'DESC';
export const DIRECTION_ASC = 'ASC';

export const PLAN_DESIGN_DATA_TYPE = {
  MEDICAL: 'MEDICAL',
  DENTAL: 'DENTAL',
  VISION: 'VISION'
};

//BACKEND KEY
export const KEY_HSA_TYPE_IN_FAMILY = 'IN_FAMILY';
export const KEY_HSA_TYPE_ELECTION_TIER = 'ELECTION_TIER';
export const KEY_HSA_TYPE_MAP = {
  ELECTION_TIER: 'Election tier',
  IN_FAMILY: 'Individual and family'
};

export const KEY_SLG_PERSON_ID = 'PERSON_ID';

//ERROR MSG
export const ERROR_MSG_REQUIRED_FIELD = 'This field is required.';
export const ERROR_MSG_DUPLICATE_FIELD = 'Duplicate entry found.';

export const SLC_MONTHLY_AGG_TYPE = {
  ELECTION_TIER: 'ELECTION_TIER',
  IND_FAM: 'IND_FAM',
  COMPOSITE: 'COMPOSITE'
};

export const DEFAULT_SEARCH_PAGE_SIZE = [10, 25, 50, 100];
export const DEFAULT_SELECT_PAGE_SIZE = DEFAULT_SEARCH_PAGE_SIZE[0];

export const DEFAULT_REACT_HOOK_FIELD_ID = '_react_hook_field_Id';

export const LOGO_DEFAULT_TYPE = 'DEFAULT_LOGO';
export const LOGO_TENANT_LOGO = 'LOGO_TENANT_LOGO';

export const PLAN_EXPENSE_RATE_BASIS_PER_CLAIM = 'Per Claim';
export const PLAN_EXPENSE_EXPENSE_NAME_RX_REBATE = 'rx rebate';
export const PLAN_EXPENSE_EXPENSE_NAME_RX_REBATE_1 = 'rx rebate 1';
export const PLAN_EXPENSE_EXPENSE_NAME_RX_REBATE_2 = 'rx rebate 2';

export const PERMISSION_LIST = {
  MD_LKP: 'MD_LKP',
  MD_DI: 'MD_DI',
  MD_VAULT: 'MD_VAULT'
};

export const APP_MAPPING = {
  FrontOffice: 'FRONT_OFFICE',
  MasterData: 'MASTER_DATA',
  Ranger: 'RANGER'
};

export const ERROR_CODE = {
  TOKEN_IS_EXPIRED: 'TOKEN_IS_EXPIRED',
  MANDATORY_FIELD_MISSING: 'MANDATORY_FIELD_MISSING',
  NO_APP_AVAILABLE: 'NO_APP_AVAILABLE',
  NO_COMPANY_AVAILABLE: 'NO_COMPANY_AVAILABLE',
  NO_TABLEAU_SITE_AVAILABLE: 'NO_TABLEAU_SITE_AVAILABLE',
  USER_NOT_FOUND: 'USER_NOT_FOUND',
  OPT_NOT_MATCH: 'OPT_NOT_MATCH',
  DELETION_FAILED: 'DELETION_FAILED',
  USER_IS_INACTIVE: 'USER_IS_INACTIVE'
};

export const REPORT_EVENT = {
  REPORT_CLICK: 'REPORT_CLICK'
};

export const CATEGORY_OPTIONS = [
  { name: 'All', value: 'ALL' },
  { name: 'Smart', value: 'SMART' },
  { name: 'Exclusive', value: 'EXCLUSIVE' },
  { name: 'Custom', value: 'CUSTOM' },
  { name: 'Explorer', value: 'EXPLORER' },
  { name: 'Legacy', value: 'LEGACY' },
  { name: 'Metrics', value: 'METRICS' }
];

export const RENEWAL_ACCOUNT_TYPE_MAPPING = {
  CORE: 'Core',
  ENHANCED: 'Enhanced',
  MD_ONLY: 'MD Only',
  STATIC_CLIENT: 'Static Client'
};

export const RENEWAL_ACCOUNT_TYPE_OPTIONS = [
  { name: 'Core', value: 'CORE' },
  { name: 'Enhanced', value: 'ENHANCED' },
  { name: 'MD Only', value: 'MD_ONLY' },
  { name: 'Static Client', value: 'STATIC_CLIENT' }
];

export const RENEWAL_ACCOUNT_TYPE = {
  CORE: 'CORE',                 // requires renewal questionnaire
  ENHANCED: 'ENHANCED',         // requires renewal questionnaire and master data upload
  MD_ONLY: 'MD_ONLY',           // requires master data upload
  STATIC_CLIENT: 'STATIC_CLIENT' // does not require renewal
};

export const CASE_STATUS = {
  CREATED: 'CREATED',
  PENDING_CHANGE_FORM: 'PENDING_CHANGE_FORM',
  PENDING_MD_FILE: 'PENDING_MD_FILE',
  READY_TO_RENEW: 'READY_TO_RENEW',
  COMPLETED: 'COMPLETED'
};

export const CASE_STATUS_LIST = [
  ['CREATED', 'Created'],
  ['PENDING_CHANGE_FORM', 'Pending Change Form'],
  ['PENDING_MD_FILE', 'Pending MD File'],
  ['READY_TO_RENEW', 'Processing'],
  ['COMPLETED', 'Completed']
];

export const ANNUAL_PLAN_RENEWAL_CASE_STATUS_LIST = [
  ['PENDING_CHANGE_FORM', 'Pending Renewal Questionnaire'],
  ['PENDING_MD_FILE', 'Pending Renewal Upload'],
  ['READY_TO_RENEW', 'Processing'],
  ['COMPLETED', 'Completed']
];

export const ALL_YEARS = 'All';
export const OPEN_CASES = 'Open Cases';

export const CASE_STATUS_MAP = new Map(CASE_STATUS_LIST);

export const CATEGORY_VIEW_MAPPING = {
  SMART: 'Smart',
  CUSTOM: 'Custom',
  LEGACY: 'Standard Reports',
  EXPLORER: 'Explorer',
  METRICS: 'Metrics',
  EXCLUSIVE: 'Exclusive'
};

export const GLOBAL_REPORT_MODES = {
  NEW_REPORT: 'new-report',
  OVERWRITE_BY_DISPLAY_TEXT: 'overwrite-by-displaytext',
  OVERWRITE_BY_URL: 'overwrite-by-url'
};

export const ALL = 'All';

export const EDITABLE_CELL_TYPE = {
  DATE: 'date',
  DROPDOWN: 'dropdown',
  NUMBER: 'number'
};

export const ANNUAL_PLAN_RENEWAL_STAGES = {
  RENEWAL_QUESTIONNAIRE: {
    label: 'Renewal Questionnaire',
    value: 'RENEWAL_QUESTIONNAIRE',
    tooltip: 'Renewal Questionnaire',
    notRequired: 'Not Required'
  },
  ANNUAL_RENEWAL_UPLOAD: {
    label: 'Annual Renewal Upload',
    value: 'ANNUAL_RENEWAL_UPLOAD',
    tooltip: 'Plan Year documentation',
    notRequired: 'Not Required'
  },
  PROCESSING: {
    label: 'Processing',
    value: 'PROCESSING',
    tooltip: 'Processing provided documentation'
  },
  COMPLETED: {
    label: 'Completed',
    value: 'COMPLETED',
    tooltip: ''
  }
};

export const MAX_UPLOAD_FILE_SIZE = 10 * 1024 * 1024; // 10MB
