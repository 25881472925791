import MaterialTable, { MTableToolbar } from '@material-table/core';
import { Box, Chip, FormControl, IconButton, InputLabel, MenuItem, Select, Tooltip, makeStyles } from '@material-ui/core';
import Checkbox from '@material-ui/core/Checkbox';
import ListItemIcon from '@material-ui/core/ListItemIcon';

import ListItemText from '@material-ui/core/ListItemText';
import { confirmDialog } from 'common/ComfirmationDialog';
import { CASE_STATUS_LIST, CASE_STATUS_MAP, DEFAULT_SEARCH_PAGE_SIZE, DEFAULT_SELECT_PAGE_SIZE, EDITABLE_CELL_TYPE, MAX_UPLOAD_FILE_SIZE, RENEWAL_ACCOUNT_TYPE_MAPPING } from 'common/Constant';
import EditableTableCell from 'common/Grid/EditableTableCell';
import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as AppActionTypes from 'store/actions/appstate';
import { store } from 'store/store';
import { v4 as uuid } from 'uuid';
import * as Action from './action/action';
import MdRenewalCreationDialog from './components/MdRenewalCreationDialog';

import { Add, CloudDownload} from '@material-ui/icons';
import EmailIcon from '@material-ui/icons/Email';
import { AppConfig } from 'AppConfig';
import axios from 'axios';
import { StatusCodes } from 'http-status-codes';
import { APPSTATE_SET_APP_ERROR } from 'store/actions/appstate';
import MdFileDowloadDialog from './components/MdFileDowloadDialog';

const MdRenewalManagement = () => {
  const ALL_YEARS = 'All';
  const ALL_UNDONE = 'All Undone';
  const tableRef = useRef({});

  const useStyles = makeStyles((theme) => ({
    root: {
      display: 'flex',
      flexWrap: 'wrap',
      '& > *': {
        margin: theme.spacing(0.5)
      }
    },
    planYearSelect: {
      marginLeft: '1rem',
      minWidth: 150
    },   
    iconButton: {
      margin: '0 -8px'
    }
  }));
  const classes = useStyles();
  const dispatch = useDispatch();
  const { shouldRefresh } = useSelector((state) => state.mdRenewalManagement);

  const [creationOpen, setCreationOpen] = useState(false);

  const [fileModalIsOpen, setFileModalIsOpen] = useState(false);
  const [cloneData, setCloneData] = useState({});

  const [planYears, setPlanYears] = useState([new Date().getFullYear().toString()]);

  const [selectedStatus, setSelectedStatus] = useState([ALL_UNDONE]);

  const [pageSize, setPageSize] = useState(DEFAULT_SELECT_PAGE_SIZE);

  const [updateViewOnly, setUpdateViewOnly] = useState(false);
  const [currentEditingCell, setCurrentEditingCell] = useState({
    id: '',
    field: ''
  });
  const [updatedRows, setUpdatedRows] = useState({});

  const [caseId, setCaseId] = useState('');
  const [files, setFiles] = useState([]);

  const statusCodeDropdownOptions = CASE_STATUS_LIST?.map(([value, label]) => ({ value, label }));

  const totalPlanYears = useSelector((state) => state.mdRenewalManagement.planYears);
  const isFirstRender = useRef(false);

  const refreshPlanYears = () => {
    store.dispatch(Action.fetchAllPlanYears());
  };

  const resetInlineEdit = () => {
    setUpdateViewOnly(false);
    setUpdatedRows({});
    setCurrentEditingCell({ id: '', field: '' });
  };

  const refresh = () => {
    tableRef.current && tableRef.current.onQueryChange();
  };

  const handlePlanYearSelection = (event) => {
    const inputVal = event.target.value;
    if (inputVal.length === 0 || inputVal[inputVal.length - 1] === ALL_YEARS) {
      setPlanYears([ALL_YEARS]);
    } else {
      setPlanYears(inputVal.filter((yr) => yr !== ALL_YEARS));
    }
  };

  const handleCaseStatusSelection = (event) => {
    const inputVal = event.target.value;
    if (inputVal.length === 0 || inputVal[inputVal.length - 1] === ALL_UNDONE) {
      setSelectedStatus([ALL_UNDONE]);
    } else {
      setSelectedStatus(inputVal.filter((caseStatus) => caseStatus !== ALL_UNDONE));
    }
  };

  const isAllSelected = planYears.length === 1 && planYears[0] === ALL_YEARS;
  const isUndoneSelected = selectedStatus.length === 1 && selectedStatus[0] === ALL_UNDONE;

  const handleSave = () => {
    const ids = Object.keys(updatedRows);
    const datas = tableRef.current.getRenderData().filter((item) => {
      return ids.some((id) => id === item.id?.toString());
    });
    datas?.forEach((data) => {
      dispatch(Action.updateRenewalCase(data?.id, data?.statusCode));
    });
    resetInlineEdit();
  };

  const checkUnsavedRecords = () => {
    if (Object.keys(updatedRows).length) {
      confirmDialog(
        () => {
          handleSave();
        },
        `There are unsaved records. Do you want to save?`,
        'Save Records',
        'Cancel',
        'Save',
        () => {
          resetInlineEdit();
          refresh();
        }
      );
    }
  };

  const handleOpen = (data) => {
    setCloneData(data);
    setFileModalIsOpen(!fileModalIsOpen);
  };

  useEffect(() => {
    checkUnsavedRecords();
  }, [planYears, selectedStatus]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    refresh();
    refreshPlanYears();
  }, [shouldRefresh, planYears, selectedStatus]);

  useEffect(() => {
    refreshPlanYears();
    dispatch(Action.fetchTenantList());
    isFirstRender.current = true;
  }, []); // eslint-disable-line react-hooks/exhaustive-deps


  useEffect(() => {
    if (isFirstRender.current === true) {
    setPlanYears([ALL_YEARS]);
    isFirstRender.current=false;
    }
  }, [isFirstRender.current]);

  const handleCellValueChange = (id, field, value) => {
    const mdRenewalManagementData = tableRef.current.getRenderData();
    const index = mdRenewalManagementData.findIndex((item) => item.id === id);
    if (index > -1) {
      const tempData = [...mdRenewalManagementData];
      tempData[index] = { ...tempData[index], [field]: value };

      tableRef.current && tableRef.current.onQueryChange({ tableRef, rowIndex: index, field, newValue: value });
      setUpdateViewOnly(true);

      const temp = { ...updatedRows };
      if (updatedRows[id]) {
        temp[id].add(field);
      } else {
        temp[id] = new Set([field]);
      }
      setUpdatedRows(temp);
    }
  };

  const handleAddClick = async (rowData) => {
    setCaseId(rowData?.id);
    try {
      const res = await axios.get(AppConfig.CLIENT_PORTAL_URL + `/annual-renewal/file/list/${rowData.id}`);
      setFiles(res?.data || []);
      document.getElementById('mdFileInput').click();
    } catch (error) {
      dispatch({ type: APPSTATE_SET_APP_ERROR, payload: error });
    }
  };

  const handleEmailClick =(data) => {
    try {
      axios.post(AppConfig.CLIENT_PORTAL_URL + `/case-management/send/email/PoC`, {"username": data?.username});
    } catch (error) {
      dispatch({ type: APPSTATE_SET_APP_ERROR, payload: error });
    }
  };

  const uploadMdFile = async (fileToUpload) => {
    if (!fileToUpload) {
      return;
    }
    const formData = new FormData();
    formData.append('file', fileToUpload);
    formData.append('fileName', fileToUpload.name);
    await axios
      .post(AppConfig.CLIENT_PORTAL_URL + `/annual-renewal/file/upload/${caseId}`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      })
      .then(
        (res) => {
          if (res?.status === StatusCodes.OK || res?.status === StatusCodes.CREATED) {
            store.dispatch({
              type: AppActionTypes.APP_REST_CALL_SUCESSED,
              payload: res?.data?.message || 'File uploaded successfully'
            });
          }
        },
        (error) => {
          dispatch({ type: APPSTATE_SET_APP_ERROR, payload: error });
        }
      );
  };

  const handleFileChange = async (e) => {
    e.preventDefault();
    if (!e?.target?.files) {
      return;
    }

    const fileToUpload = e?.target?.files[0];

    if (fileToUpload?.size > MAX_UPLOAD_FILE_SIZE) {
      dispatch({ type: APPSTATE_SET_APP_ERROR, payload: `File uploaded is greater than ${MAX_UPLOAD_FILE_SIZE / (1024 * 1024)}MB, please reduce the size and try again.`});
      return;
    } else if (files?.length > 0 ) {
      const fileIndex = files.findIndex((p) => p.s3FileUrl.replace(/^.*[\\/]/, '') === fileToUpload?.name);

      if (fileIndex !== -1) {
        dispatch({ type: APPSTATE_SET_APP_ERROR, payload: `The file name ${fileToUpload?.name} is already in use.`});
        return;
      }
    }
    await uploadMdFile(fileToUpload);
  };

  return (
    <>
      <MaterialTable
        title=""
        style={{ width: '100%' }}
        tableRef={tableRef}
        columns={[
          { title: 'Case year', field: 'year', editable: 'never' },
          { title: 'Tenant Code', field: 'tenantCode', editable: 'never' },
          { title: 'Tenant Name', field: 'tenantName', editable: 'never', sorting: false },
          { title: 'Broker', field: 'brokerName', editable: 'never', sorting: false },
          { title: 'Due Date', field: 'mdDueDate', editable: 'never' },
          {
            title: 'Renewal Account Type',
            field: 'renewalAccountType',
            render: (data) => RENEWAL_ACCOUNT_TYPE_MAPPING[data.renewalAccountType] || 'Unknown',
            editable: 'never',
            sorting: false
          },
          {
            title: 'Case Status',
            field: 'statusCode',
            render: (rowdata) => (
              <EditableTableCell
                rowData={rowdata}
                displayData={CASE_STATUS_MAP.get(rowdata.statusCode) || 'Unknown'}
                field="statusCode"
                editorType={EDITABLE_CELL_TYPE.DROPDOWN}
                showEdit={rowdata.id === currentEditingCell.id && currentEditingCell.field === 'statusCode'}
                isEdited={updatedRows[rowdata.id] && updatedRows[rowdata.id].has('statusCode')}
                options={statusCodeDropdownOptions}
                onCellClick={(id, field) => setCurrentEditingCell({ id, field })}
                key={rowdata.id}
                onChange={handleCellValueChange}
              />
            )
          },
          { title: 'Days in Current Status', field: 'daysInCurrentStatus', editable: 'never', sorting: false },
          {
            title: 'MD files',
            field: 's3BucketUrl',
            editable: 'never',
            sorting: false,
            render: (rowData) => (
              <>
                <Tooltip title={rowData.s3BucketUrl ? 'Download MD files' : 'No MD files'}>
                  <span>
                    <IconButton className={classes.iconButton} onClick={() => rowData.s3BucketUrl && handleOpen({ rowData, transientItem: false })}>
                      <CloudDownload color={rowData.s3BucketUrl ? 'inherit' : 'disabled'} />
                    </IconButton>
                  </span>
                </Tooltip>
                <Tooltip title="Upload MD files">
                  <IconButton className={classes.iconButton} onClick={() => handleAddClick(rowData)}>
                    <Add />
                  </IconButton>
                </Tooltip>
              </>
            )
          },
          {
            title: 'Contact Name',
            field: 'contacts',
            editable: 'never',
            sorting: false,
            render: (rowData) => (
              <div className={classes.root}>
                {rowData?.contacts?.map((data) => (
                  <Chip
                    key={'key-' + uuid()}
                    label={data?.fullName + ' <' + data?.email + '>'}
                    icon={
                      <Tooltip title={'Email ' + data?.fullName}>
                        <div>
                          <IconButton className={classes.iconButton} onClick={() => handleEmailClick(rowData.contacts?.find((c) => c.fullName === data?.fullName))}>
                            <EmailIcon />
                          </IconButton>
                        </div>
                      </Tooltip>
                    }
                  />
                ))}
              </div>
            )
          }
        ]}
        onChangeRowsPerPage={setPageSize}
        onRowsPerPageChange={checkUnsavedRecords}
        onPageChange={checkUnsavedRecords}
        onSearchChange={checkUnsavedRecords}
        onOrderCollectionChange={checkUnsavedRecords}
        data={(query) => Action.getMdRenewalManagementTableData(query, planYears, selectedStatus, updateViewOnly)}
        options={{
          actionsColumnIndex: -1,
          search: true,
          pageSizeOptions: DEFAULT_SEARCH_PAGE_SIZE,
          pageSize,
          debounceInterval: 400
        }}
        components={{
          OverlayLoading: () => <div />,
          Toolbar: (props) => (
            <Box>
              <MTableToolbar {...props} />
              <FormControl className={classes.planYearSelect} size="medium">
                <InputLabel id="label_planYear">Case Year:</InputLabel>
                <Select multiple id="input_planYear" key="input_planYear" onChange={handlePlanYearSelection} value={planYears} renderValue={(planYears) => planYears.join(', ')}>
                  <MenuItem value={ALL_YEARS}>
                    <ListItemIcon>
                      <Checkbox checked={isAllSelected} />
                    </ListItemIcon>
                    <ListItemText primary={ALL_YEARS} />
                  </MenuItem>
                  {totalPlanYears &&
                    Array.isArray(totalPlanYears) &&
                    totalPlanYears.map((item) => (
                      <MenuItem value={item} key={item}>
                        <ListItemIcon>
                          <Checkbox checked={planYears.indexOf(item) > -1 || isAllSelected} />
                        </ListItemIcon>
                        <ListItemText primary={item} />
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
              <FormControl className={classes.caseStatusSelect} size="medium">
                <InputLabel id="label_status">Case status:</InputLabel>
                <Select
                  multiple
                  id="input_status"
                  key="input_status"
                  onChange={handleCaseStatusSelection}
                  value={selectedStatus}
                  renderValue={(selected) => selected?.map((key) => [[ALL_UNDONE, ALL_UNDONE], ...CASE_STATUS_LIST]?.find((option) => option[0] === key)[1])?.join(', ')}>
                  <MenuItem value={ALL_UNDONE}>
                    <ListItemIcon>
                      <Checkbox checked={isUndoneSelected} />
                    </ListItemIcon>
                    <ListItemText primary={ALL_UNDONE} />
                  </MenuItem>
                  {CASE_STATUS_LIST &&
                    Array.isArray(CASE_STATUS_LIST) &&
                    CASE_STATUS_LIST.map((key, value) => (
                      <MenuItem value={key[0]} key={key}>
                        <ListItemIcon>
                          <Checkbox checked={selectedStatus.indexOf(key[0]) > -1 || (isUndoneSelected && key[0] !== 'COMPLETED')} />
                        </ListItemIcon>
                        <ListItemText primary={key[1]} />
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            </Box>
          )
        }}
        actions={[
          {
            icon: 'add',
            onClick: () => setCreationOpen(true),
            isFreeAction: true
          },
          {
            icon: 'save',
            tooltip: 'Save',
            isFreeAction: true,
            disabled: Object.keys(updatedRows).length === 0,
            onClick: (_event, _rowData) => {
              handleSave();
            }
          }
        ]}
      />
      <input
        type="file"
        id="mdFileInput"
        style={{ display: 'none' }}
        onChange={handleFileChange}
        onClick={(event) => {
          // Reset the input value to allow the same file to be selected again
          event.target.value = null;
        }}
      />
      <MdRenewalCreationDialog open={creationOpen} target={{}} onClose={() => setCreationOpen(false)} />
      <MdFileDowloadDialog open={fileModalIsOpen} onClose={() => setFileModalIsOpen(false)} target={cloneData} />
    </>
  );
};

export default MdRenewalManagement;
