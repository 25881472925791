import axios from 'axios';
import { AppConfig } from 'AppConfig';

export default function getUrlTicket(aUrl) {
  return new Promise(function (resolve, reject) {
    if (aUrl == null || aUrl.indexOf('localhost') > 0) {
      return resolve(aUrl);
    }
    if (aUrl.indexOf('dummyip') > 0) {
      aUrl = aUrl.replace('dummyip', AppConfig.TB_DEV_URL);
    }

    axios
      .post(AppConfig.FO_INITIAL_URL + '/tbl/ticket')
      .then((r) => {
        if (r == null) {
          reject(`Tableau response undefined on: ${aUrl}`);
        } else if (aUrl.indexOf('tableauToken') > 0) {
          aUrl = aUrl.replace(/tableauToken/, r.data);
        } else {
          const parts = aUrl.split('/');
          parts[4] = r.data;
          aUrl = parts.join('/');
        }
        resolve(aUrl);
      })
      .catch((e) => {
        reject(e);
      });
  });
}
