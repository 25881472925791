import * as Types from './userTypes';
import { AppConfig } from 'AppConfig';
import jwt from 'jwt-decode';

const initialState = {
  allBrokers: [],
  apps: [],
  companies: [],
  currentApp: null,
  currentBroker: '',
  currentTenantCd: null,
  deafultUserState: true,
  email: '',
  enigmaAdmin: false,
  firstName: '',
  foAdmin: false,
  hasEnigmaAccess: false,
  headerType: AppConfig.HEADER_MENU_TYPE.STANDARD,
  id: -1,
  internal: false,
  isUpdating: false,
  lastLoginDt: undefined,
  lastName: '',
  mdAdmin: false,
  otpMatched: false,
  permissions: [],
  planOptimizeAdmin: false,
  securityAdmin: false,
  sysAdmin: false,
  tableauRole: '',
  tableauServiceUrl: null,
  tableauUsername: '',
  tenantApps: [],
  termsAccepted: false,
  username: '',
  enigmaTenantAdmin: false,
  planOptimizeTenantAdmin: false
};

const sortCompanies = (companies) => {
  return companies && Array.isArray(companies)
    ? companies.sort((first, second) =>
        first.name.toLowerCase().localeCompare(second.name.toLowerCase())
      )
    : [];
};

const sortBrokers = (brokers) => {
  return brokers && Array.isArray(brokers)
    ? brokers.sort((first, second) =>
        first.name.toLowerCase().localeCompare(second.name.toLowerCase())
      )
    : [];
};

export default (state = initialState, action) => {
  switch (action.type) {
    case Types.FETCH_DATA:
    case Types.LOGIN:
      return {
        ...state,
        currentTenantCd: null,
        apps: [],
        roles: [],
        permissions: [],
        isUpdating: true
      };
    case Types.FETCH_DATA_SUCCESS:
      return {
        ...state,
        id: action.payload.userId,
        email: action.payload.email,
        username: action.payload.username,
        currentApp: action.payload.currentApp,
        currentTenantCd: action.payload.currentTenantCd,
        apps: action.payload.apps,
        permissions: action.payload.permissions,
        companies: sortCompanies(action.payload.companies),
        firstName: action.payload.firstName,
        lastName: action.payload.lastName,
        isUpdating: false,
        tenantApps: action.payload.tenantApps,
        hasEnigmaAccess: action.payload.hasEnigmaAccess,
        tableauServiceUrl: action.payload?.tbUrl,
        deafultUserState: false,
        sysAdmin: action.payload.systemAdmin,
        mdAdmin: action.payload.mdAdmin,
        foAdmin: action.payload.foAdmin,
        enigmaAdmin: action.payload?.enigmaAdmin,
        planOptimizeAdmin: action.payload?.planOptimizeAdmin,
        enigmaTenantAdmin: action.payload?.enigmaTenantAdmin,
        planOptimizeTenantAdmin: action.payload?.planOptimizeTenantAdmin
      };
    case Types.LOGIN_SUCCESS:
      return {
        ...state,
        apps: action.payload.apps || state.apps,
        companies: sortCompanies(action.payload.companies) || state.companies,
        currentApp: action.payload.currentApp || state.currentApp,
        currentTenantCd:
          action.payload.currentTenantCd || state.currentTenantCd,
        deafultUserState: false || state.deafultUserState,
        email: action.payload.email || state.email,
        enigmaAdmin: action.payload.isEnigmaAdmin || state.enigmaAdmin,
        firstName: action.payload.firstName || state.firstName,
        foAdmin: action.payload.foAdmin || state.foAdmin,
        hasEnigmaAccess:
          action.payload.hasEnigmaAccess || state.hasEnigmaAccess,
        id: action.payload.id || state.id, //I only see id, not userId in the result.
        isUpdating: false || state.isUpdating,
        lastLoginDt: action.payload.lastLoginDt || state.lastLoginDt,
        lastName: action.payload.lastName || state.lastName,
        mdAdmin: action.payload.mdAdmin || state.mdAdmin,
        permissions: action.payload.permissions || state.permissions,
        planOptimizeAdmin:
          action.payload.isPlanOptimizeAdmin || state.planOptimizeAdmin,
        sysAdmin: action.payload.systemAdmin || state.sysAdmin,
        tableauUsername:
          action.payload.tableauUsername || state.tableauUsername,
        tenantApps: action.payload.tenantApps || state.tenantApps,
        username: action.payload.username || state.username
      };
    case Types.LOGOUT:
    case Types.FETCH_DATA_FAILURE:
    case Types.LOGIN_FAILURE:
      return initialState;
    case Types.BROKER_SET:
      return {
        ...state,
        currentBroker: action.payload
      };
    case Types.APP_SWITCHED:
      return {
        ...state,
        headerType: action.payload
      };
    case Types.JWT:
      try {
        const jwtObj = jwt(action.payload);
        return {
          ...state,
          permissions: jwtObj.permission,
          apps: jwtObj.apps,
          firstName: jwtObj.firstName,
          lastName: jwtObj.lastName,
          tenantApps: jwtObj.KEY_JWT_TENANT_APPS,
          jwtExp: jwtObj.exp || state.jwtExp
        };
      } catch (error) {
        //donothing
        console.error('invalid JWT: ' + action.payload);
        return state;
      }
    case Types.FETCH_ALL_BROKERS:
      return {
        ...state,
        allBrokers: sortBrokers(action.payload)
      };
    case Types.RESET_APPS_TENANT:
      return {
        ...state,
        currentApp: null,
        currentTenantCd: null
      };
      case Types.RESET_APP:
        return {
          ...state,
          currentApp: null,
        };
    default:
      return state;
  }
};
