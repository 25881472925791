import React, { useState } from 'react';
import { MTableToolbar } from '@material-table/core';
import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';

const CustomToolbar = (props) => {
  const { columns, onFilterChange } = props;
  const [filterValue, setFilterValue] = useState('');

  const handleFilterChange = (event) => {
    const value = event.target.value;
    setFilterValue(value);
    const column = columns.find((col) => col.field === 'refreshStatus');
    if (column && onFilterChange) {
      onFilterChange(column.tableData.id, value || '');
    }
  };

  return (
    <div style={{ display: 'flex', alignItems: 'center', width: '100%' }}>
      <FormControl variant="outlined" style={{ marginRight: '16px', minWidth: '200px' }}>
        <InputLabel>Refresh Status</InputLabel>
        <Select label="Refresh Status" onChange={handleFilterChange} value={filterValue}>
          <MenuItem value="OK">OK</MenuItem>
          <MenuItem value="Complete">Complete</MenuItem>
          <MenuItem value="Very Late">Very Late</MenuItem>
          <MenuItem value="Late">Late</MenuItem>
          <MenuItem value="Pending">Pending</MenuItem>
          <MenuItem value="Queued">Queued</MenuItem>
          <MenuItem value="Running">Running</MenuItem>
        </Select>
      </FormControl>
      <div style={{ flexGrow: 1 }}>
        <MTableToolbar {...props} />
      </div>
    </div>
  );
};

export default CustomToolbar;