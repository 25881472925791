import React, { useEffect } from 'react';
import {
  TextField,
  Grid,
  Tooltip,
  List,
  ListSubheader,
  FormControl,
  InputLabel,
  Select,
  FormHelperText,
  MenuItem
} from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { createFilterOptions } from '@material-ui/lab/Autocomplete';
import { Controller, useFormContext } from 'react-hook-form';
import { makeStyles } from '@material-ui/core/styles';
import { useSelector } from 'react-redux';
import * as ValidationUtil from 'utils/ValidationUtil';
import axios from 'axios';
import { AppConfig } from 'AppConfig';
import {v4 as uuid} from 'uuid';
import * as Actions from 'features/admin/userAccess/action/action';
import { store } from 'store/store';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
    position: 'relative',
    overflow: 'auto',
    maxHeight: 250
  },
  listSection: {
    backgroundColor: 'inherit'
  },
  ul: {
    marginTop: '-10px',
    marginLeft: '20px',
    backgroundColor: 'inherit',
    padding: 0
  }
}));

const RoleManagementDialogDetail = (props) => {

  const appCodeMapping = {
    FRONT_OFFICE: 'FO_',
    MASTER_DATA: 'MD_',
    ENIGMA: 'ENIGMA_',
    PLAN_OPTIMIZE: 'PLAN_OPT'
  };

  const classes = useStyles();

  const { permissionList } = props;

  const { control, getValues, watch } = useFormContext();

  const applications = useSelector(
    (state) => state.adminUserAccess.applicationList
  );

  const permissionFilterOptions = createFilterOptions({
    stringify: (option) => `${option.code} ${option.description}`
  });

  const watchPermissions = watch('permissions', null);
  const watchApplications = watch('applicationCode', null);

  useEffect(() => {
    store.dispatch(Actions.fetchApplicationList());
  }, []);

  return (
    <>
      <Grid container spacing={2} bgcolor="primary.main">
        <Grid item xs={6}>
          <Grid
            item
            xs={12}
            style={{ marginBottom: '10px', marginTop: '20px' }}>
            <Controller
              name="code"
              control={control}
              rules={{
                required: "Role code can't be empty.",
                validate: async (value) => {
                  if (value) {
                    if (!/^[A-Z0-9_]*$/.test(value))
                      return 'Only upper case letters, numbers and underscore allowed.';
                    const found = await axios.get(
                      AppConfig.IAM_BACKEND_URL + `/role/role-code?code=${value}`
                    );
                    return ValidationUtil.VALIDATION_DUPLICATION(
                      found,
                      parseInt(getValues('id')),
                      'Duplicated Role Code is Found.'
                    );
                  }
                }
              }}
              render={({ field, fieldState: { error } }) => (
                <TextField
                  {...field}
                  fullWidth
                  required
                  value={field.value ?? ''}
                  error={error}
                  helperText={error ? error.message : null}
                  id={'input-role-code'}
                  label="Role Code"
                />
              )}
            />
          </Grid>

          <Grid
            item
            xs={12}
            style={{ marginBottom: '10px', marginTop: '20px' }}>
            <Controller
              name="description"
              control={control}
              rules={{
                required: "Role description can't be empty."
              }}
              render={({ field, fieldState: { error } }) => (
                <TextField
                  {...field}
                  fullWidth
                  required
                  value={field.value ?? ''}
                  error={error}
                  helperText={error ? error.message : null}
                  id={'input-role-description'}
                  label="Role Description"
                />
              )}
            />
          </Grid>

          <Grid
            item
            xs={12}
            style={{ marginBottom: '10px', marginTop: '20px' }}>
            <Controller
              name="applicationCode"
              control={control}
              render={({ field, fieldState: { error } }) => (
                <>
                  <FormControl fullWidth error={error}>
                    <InputLabel id="label-application-input">
                      Application
                    </InputLabel>
                    <Select
                      fullWidth
                      {...field}
                      labelId="label-application"
                      id="input-application">
                      {applications &&
                        Array.isArray(applications) &&
                        applications.map((item) => {
                          return (
                            <MenuItem key={'key-' + uuid()} value={item.code}>
                              {item.description}
                            </MenuItem>
                          );
                        })}
                    </Select>

                    <FormHelperText>
                      {error ? error.message : null}
                    </FormHelperText>
                  </FormControl>
                </>
              )}
            />
          </Grid>

          <Grid
            item
            xs={12}
            style={{ marginBottom: '10px', marginTop: '20px' }}>
            <Controller
              name="permissions"
              control={control}
              render={({ field, fieldState: { error } }) => (
                <Autocomplete
                  {...field}
                  multiple
                  fullWidth
                  id="permissions"
                  disabled={watchApplications ? false : true}
                  options={permissionList.filter((x) =>
                    x.code.startsWith(appCodeMapping[watchApplications])
                  )}
                  getOptionLabel={(option) => (option.code ? option.code : '')}
                  getOptionSelected={(option, value) => option.id === value.id}
                  filterOptions={permissionFilterOptions}
                  onChange={(_, data) => field.onChange(data)}
                  value={getValues('permissions') ?? []}
                  filterSelectedOptions
                  PopperComponent={'bottom'}
                  renderOption={({ code, description, ...props }) => {
                    return (
                      <Tooltip title={description} placement="bottom-start">
                        <TextField
                          InputProps={{
                            readOnly: true,
                            disableUnderline: true
                          }}
                          fullWidth
                          defaultValue={code}
                        />
                      </Tooltip>
                    );
                  }}
                  renderInput={(field) => (
                    <TextField
                      {...field}
                      id={'input-permissions'}
                      label="Permissions"
                      placeholder="Chosen Permissions"
                    />
                  )}
                />
              )}
            />
          </Grid>
        </Grid>

        <Grid item xs={6}>
          <List>
            <ListSubheader component="div" id="permission-list-subheader">
              Permission Detail
            </ListSubheader>
            <ul className={classes.ul} type="none">
              {watchPermissions && watchPermissions.length > 0
                ? watchPermissions.map((permission) => (
                  <li key={'key-' + uuid()}>
                    <b>{permission.code}</b> {': ' + permission.description}
                  </li>
                ))
                : 'No permission was found here.'}
            </ul>
          </List>
        </Grid>
      </Grid>
    </>
  );
};

export default RoleManagementDialogDetail;
