import React, { useState, useEffect, useRef } from 'react';
import * as Constant from 'common/Constant';
import { useSelector } from 'react-redux';
import { store } from 'store/store';
import * as UserTypes from 'features/user/userTypes';
import { AppConfig } from 'AppConfig';
import axios from 'axios';
import MaterialTable from '@material-table/core';
import { getAllBrokers } from 'features/user/userActions';
import { fetchBrokerCompanyMapping } from '../vendor-labeling/actions/action';
import TenantCreationDialog from './components/tenantCreationDialog';
import * as Action from './action/action';
import { Checkbox, Tooltip } from '@material-ui/core';
import { confirmDialog } from 'common/ComfirmationDialog';
import { RENEWAL_ACCOUNT_TYPE_MAPPING } from 'common/Constant';
import SearchIcon from '@material-ui/icons/Search';

const TenantManagement = () => {
  const tableRef = useRef({});

  const shouldRefresh = useSelector((state) => state.tenantManagement.shouldRefresh);

  const [creationOpen, setCreationOpen] = useState(false);

  const tenantData = useRef({});

  const handleCreationOpen = (rowData) => {
    tenantData.current = rowData;
    setCreationOpen(true);
  };

  const handleCreationClose = () => {
    setCreationOpen(false);
  };

  const [pageSize, setPageSize] = useState(Constant.DEFAULT_SELECT_PAGE_SIZE);

  const refresh = () => {
    tableRef.current && tableRef.current.onQueryChange();
  };

  const handleTenantActiveCheckboxChange = (payload) => {
    confirmDialog(
      () => (payload.active ? store.dispatch(Action.activateCompany(payload.id)) : store.dispatch(Action.deactivateCompany(payload.id))),
      `Are you sure you want to change the status of "${payload.name}" to ${payload.active ? 'active' : 'inactive'}?` +
        (payload.active ? `` : ` Go-live will be automatically set to FALSE due to tenant being inactive.`)
    );
  };

  const handleTenantOnboardedCheckboxChange = (payload) => {
    payload.state = payload.stateId;
    const { brokerCode, brokerName, stateId, tableData, ...rest } = payload;
    confirmDialog(
      () => store.dispatch(Action.updateTenantDetails(rest)),
      `Are you sure you want to change the status of "${payload.name}" to ${payload.live ? 'live' : 'not live'}?`
    );
  };

  useEffect(() => {
    refresh();
  }, [shouldRefresh]);

  useEffect(() => {
    store.dispatch({
      type: UserTypes.APP_SWITCHED,
      payload: AppConfig.HEADER_MENU_TYPE.NONE
    });
    store.dispatch(getAllBrokers());
    store.dispatch(fetchBrokerCompanyMapping());
    store.dispatch(Action.fetchStateList());
  }, []);

  return (
    <>
      <MaterialTable
        title=""
        style={{ width: '100%' }}
        tableRef={tableRef}
        columns={[
          { title: 'Code', field: 'code', editable: 'never' },
          { title: 'Name', field: 'name', editable: 'never' },
          { title: 'Broker', field: 'brokerName', editable: 'never' },
          { title: 'State', field: 'state', editable: 'never' },
          {
            title: 'Renewal Account Type',
            field: 'renewalAccountType',
            editable: 'never',
            render: (data) => RENEWAL_ACCOUNT_TYPE_MAPPING[data.renewalAccountType] || 'Unknown'
          },
          {
            title: 'Active',
            field: 'active',
            type: 'boolean',
            cellStyle: { textAlign: 'start' },
            headerStyle: { textAlign: 'center' },
            render: (rowdata) => (
              <Checkbox
                checked={rowdata.active}
                onChange={(_, checked) =>
                  handleTenantActiveCheckboxChange({
                    ...rowdata,
                    active: checked
                  })
                }
                inputProps={{ 'aria-label': 'company active checkbox' }}
                id={`input-activeCheckbox-company-active${rowdata.id}`}
                color="primary"
              />
            )
          },
          {
            title: 'Go-Live',
            field: 'live',
            type: 'boolean',
            cellStyle: { textAlign: 'start' },
            headerStyle: { textAlign: 'center' },
            render: (rowdata) =>
              !rowdata?.active ? (
                <Tooltip title="Active status is required">
                  <span>
                    <Checkbox
                      checked={rowdata.live}
                      onChange={(_, checked) =>
                        handleTenantOnboardedCheckboxChange({
                          ...rowdata,
                          live: checked
                        })
                      }
                      inputProps={{ 'aria-label': 'company onboarded checkbox' }}
                      id={`input-onboarded-Checkbox-company-onboarded${rowdata.id}`}
                      color="primary"
                      disabled={!rowdata?.active}
                    />
                  </span>
                </Tooltip>
              ) : (
                <Checkbox
                  checked={rowdata.live}
                  onChange={(_, checked) =>
                    handleTenantOnboardedCheckboxChange({
                      ...rowdata,
                      live: checked
                    })
                  }
                  inputProps={{ 'aria-label': 'company onboarded checkbox' }}
                  id={`input-onboarded-Checkbox-company-onboarded${rowdata.id}`}
                  color="primary"
                  disabled={!rowdata?.active}
                />
              )
          }
        ]}
        data={(query) =>
          new Promise((resolve, reject) => {
            axios
              .post(AppConfig.FO_INITIAL_URL + `/company-admin/search`, {
                page: query.page,
                size: query.pageSize,
                direction: query.orderDirection ? query.orderDirection.toUpperCase() : Constant.DIRECTION_ASC,
                sortProperty: query.orderBy ? query.orderBy.field : 'code',
                searchTerm: query.search
              })
              .then((m) => {
                let data = m.data;
                resolve({
                  data: data.content,
                  page: data.number,
                  size: data.size,
                  totalCount: data.totalElements
                });
              })
              .catch((err) => {
                resolve({
                  data: [],
                  page: 0,
                  totalCount: 0
                });
              });
          })
        }
        onChangeRowsPerPage={setPageSize}
        options={{
          actionsColumnIndex: -1,
          search: true,
          pageSizeOptions: Constant.DEFAULT_SEARCH_PAGE_SIZE,
          pageSize,
          debounceInterval: 400
        }}
        components={{ OverlayLoading: () => <div /> }}
        actions={[
          (data) => ({
            icon: 'edit',
            tooltip: 'Edit',
            onClick: (event, rowData) => {
              handleCreationOpen(rowData);
            },
            disabled: data.isDefault
          }),
          {
            icon: 'refresh',
            tooltip: 'Refresh Data',
            onClick: () => {
              refresh();
            },
            isFreeAction: true
          },
          {
            icon: 'add',
            onClick: () => {
              handleCreationOpen({});
            },
            isFreeAction: true
          }
        ]}
        icons={{
          Search: () => <SearchIcon color="primary" />
        }}
      />
      <TenantCreationDialog open={creationOpen} target={tenantData.current} onClose={handleCreationClose} />
    </>
  );
};

export default TenantManagement;
