import React, { useEffect } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button } from '@material-ui/core';
import { useForm, FormProvider } from 'react-hook-form';
import * as Action from '../action/action';
import { store } from 'store/store';
import TenantCreationDetail from './tenantCreationDetails';
import SubmitButton from 'common/SingleClickButton';

const TenantCreationDialog = (props) => {
  const { onClose, open, target } = props;
  const methods = useForm({ defaultValues: target, mode: 'onBlur' });
  const { reset, formState } = methods;

  const handleClose = (data) => {
    onClose(data);
  };

  const onSubmit = (data) => {
    const payload = {
      code: data.code,
      name: data.name,
      address: data.address,
      brokerId: data.brokerId?.id,
      state: data.stateId?.stateId,
      active: data.active,
      live: data.live,
      renewalAccountType: data.renewalAccountType.value
    };
    if (data.id) {
      store.dispatch(Action.updateTenantDetails(payload));
    } else {
      store.dispatch(Action.createNewTenant(payload));
    }

    onClose(data);
  };

  useEffect(() => {
    reset(target);
  }, [open, target]);

  return (
    <>
      <Dialog open={open}>
        <DialogTitle>{target && target?.id ? 'Edit' : 'New'}</DialogTitle>
        <DialogContent dividers>
          <FormProvider {...methods}>
            <TenantCreationDetail />
          </FormProvider>
        </DialogContent>
        <DialogActions>
          <SubmitButton handleSubmit={methods.handleSubmit(onSubmit)} isDirty={formState.isDirty} />
          <Button onClick={() => handleClose(null)} color="secondary">
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default TenantCreationDialog;
