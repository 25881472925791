import React from 'react';
import PropTypes from 'prop-types';
import MaterialChip, { chipClasses } from '@mui/material/Chip';
import { styled } from '@mui/material/styles';

const Chip = (props) => {
  const StyledChip = styled(MaterialChip)(({ theme }) => ({
    'color': 'white',
    'backgroundColor': `${props.chipcolor} !important`,
    [`& .${chipClasses.outlined}`]: {
      color: props.chipcolor,
      border: `1px solid ${props.chipcolor}`,
      backgroundColor: `transparent !important`,
    },
    [`& .${chipClasses.icon}`]: {
      color: props.chipVariant === 'outlined' ? props.chipcolor : 'white',
    },
    [`& .${chipClasses.deleteIcon}`]: {
      color: props.chipVariant === 'outlined' ? props.chipcolor : 'white',
    },
  }));

  return <StyledChip {...props} />;
};

Chip.propTypes = {
  chipcolor: PropTypes.string,
  chipVariant: PropTypes.oneOf(['regular, outlined']),
};

export default Chip;
