import * as FoActions from 'features/frontoffice/actions/actions';
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { store } from 'store/store';

const Inspection = () => {
  const inspection = useSelector((state) => state.fo.inspection);
  const currentTenantCd = useSelector((state) => state.user.currentTenantCd);

  useEffect(() => {
    store.dispatch(FoActions.getInspectionForTenant(currentTenantCd));
  }, [currentTenantCd, inspection]);

  return (
    <div className="site-main__top">
      <p style={{ fontSize: '10px', float: 'right' }}>{'Data available through: ' + (inspection && Object.keys(inspection).length !== 0 ? inspection : 'N/A')}</p>
    </div>
  );
};

export default Inspection;
