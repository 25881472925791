import { Divider, Grid, IconButton, makeStyles, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@material-ui/core';
import Add from '@material-ui/icons/Add';
import DeleteForeverOutlinedIcon from '@material-ui/icons/DeleteForeverOutlined';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import SystemUpdateAltRoundedIcon from '@material-ui/icons/SystemUpdateAltRounded';
import { AppConfig } from 'AppConfig';
import renewalTemplate from 'assets/template/AnnualRenewalMasterDataTemplate.xlsx';
import axios from 'axios';
import { Accordion, AccordionDetails, AccordionSummary } from 'common/BstAccordion';
import { confirmDialog } from 'common/ComfirmationDialog';
import { CASE_STATUS, RENEWAL_ACCOUNT_TYPE } from 'common/Constant';
import { ANNUAL_PLAN_RENEWAL_DETAILS } from 'features/clientPortal/modules/constant';
import { StatusCodes } from 'http-status-codes';
import moment from 'moment';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { APPSTATE_SET_APP_ERROR } from 'store/actions/appstate';
import { v4 as uuid } from 'uuid';
import UploadedFileSubmit from './UploadedFileSubmit';

const UploadedFileDetail = ({ rowData }) => {
  const useStyles = makeStyles((theme) => ({
    root: {
      display: 'flex',
      flexWrap: 'wrap',
      '& > *': {
        margin: theme.spacing(0.5)
      }
    },
    noBorderBottom: {
      borderBottom: 'none'
    }
  }));
  const classes = useStyles();
  const dispatch = useDispatch();
  const { renewalAccountType: accountType, status } = rowData;
  const isFileModificationAllowed = (accountType === RENEWAL_ACCOUNT_TYPE.MD_ONLY || accountType === RENEWAL_ACCOUNT_TYPE.ENHANCED) && status === CASE_STATUS.PENDING_MD_FILE;

  const [state, setState] = useState({
    caseId: rowData?.caseId,
    isAccordionExpanded: true,
    files: [...rowData?.mdFiles?.sort((a, b) => a.s3FileUrl.replace('-', '').localeCompare(b.s3FileUrl.replace('-', '')))]
  });

  const handleDelete = (row) => {
    confirmDialog(() => deleteMdFile(row), 'Do you really want to delete file ' + row?.s3FileUrl.replace(/^.*[\\/]/, '') + ' ?');
  };

  const deleteMdFile = (row) => {
    axios.delete(AppConfig.CLIENT_PORTAL_URL + `/annual-renewal/file/delete`, { data: { caseId: state.caseId, fileName: row.s3FileUrl } }).then(
      (res) => {
        if (res.status === StatusCodes.OK || res.status === StatusCodes.CREATED) {
          const tempArray = state.files.filter((f) => f.s3FileUrl !== row.s3FileUrl);
          setState({ ...state, files: [...tempArray] });
        }
      },
      (error) => {
        dispatch({ type: APPSTATE_SET_APP_ERROR, payload: error });
      }
    );
  };

  const downloadMdFile = (row) => {
    axios.post(AppConfig.CLIENT_PORTAL_URL + `/annual-renewal/file/download`, { caseId: state.caseId, fileName: row.s3FileUrl }, { responseType: 'blob' }).then(
      (res) => {
        const url = window.URL.createObjectURL(new Blob([res.data]));
        const fileName = row?.s3FileUrl.replace(/^.*[\\/]/, '');
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', fileName);
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
        window.URL.revokeObjectURL(url);
      },
      (error) => {
        dispatch({ type: APPSTATE_SET_APP_ERROR, payload: error });
      }
    );
  };

  const uploadMdFile = async (fileToUpload) => {
    if (!fileToUpload) {
      return;
    }
    const formData = new FormData();
    formData.append('file', fileToUpload);
    formData.append('fileName', fileToUpload.name);

    try {
      const response = await axios.post(AppConfig.CLIENT_PORTAL_URL + `/annual-renewal/file/upload/${state.caseId}`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });      

      if (response?.status === StatusCodes.OK || response?.status === StatusCodes.CREATED) {
        axios.get(AppConfig.CLIENT_PORTAL_URL + `/annual-renewal/file/list/${state.caseId}`).then(
          (response) => setState({ ...state, files: [...response?.data] }),
          (error) => {
            dispatch({ type: APPSTATE_SET_APP_ERROR, payload: error });
          }
        );
      }
    } catch (error) {
      dispatch({ type: APPSTATE_SET_APP_ERROR, payload: error });
    }
  };

  const handleFileUpload = (e) => {
    e.preventDefault();
    if (!e?.target?.files) {
      return;
    }

    if (state?.files?.length > 0 ) {
      const fileIndex = state?.files.findIndex((p) => p.s3FileUrl.replace(/^.*[\\/]/, '') === e.target?.files[0].name);

      if (fileIndex !== -1) {
        dispatch({ type: APPSTATE_SET_APP_ERROR, payload: `The file name ${e.target?.files[0].name} is already in use.`});
        return;
      }
    }
    uploadMdFile(e.target?.files[0]);
  };

  const handleExpandClick = (e) => {
    e.preventDefault();
    setState({ ...state, isAccordionExpanded: !state.isAccordionExpanded });
  };

  return (
    <>
      <Accordion expanded={state.isAccordionExpanded}>
        <AccordionSummary id="renewal-uploaded-file-header" expandIcon={<ExpandMoreIcon onClick={handleExpandClick} />}>
          <Typography>{ANNUAL_PLAN_RENEWAL_DETAILS.UPLOADED_FILE_DETAILS.SUMMARY}</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container spacing={0}>
            <Grid item xs={12}>
              <div align="center">
                <a href={renewalTemplate} download="AnnualRenewalMasterDataTemplate.xlsx" style={{ fontStyle: 'italic' }}>
                  {ANNUAL_PLAN_RENEWAL_DETAILS.UPLOADED_FILE_DETAILS.DETAILS.TITLE_TEXT}
                </a>
              </div>
            </Grid>
            <Grid item xs={12}>
              <Divider />
            </Grid>
            <Grid item xs={11}>
              {state?.files?.length > 0 ? (
                <TableContainer>
                  <Table className={classes.table} size="small" aria-label="List of uploaded files">
                    <TableHead>
                      <TableRow>
                        <TableCell className={classes.noBorderBottom} align="center">
                          File Name
                        </TableCell>
                        <TableCell className={classes.noBorderBottom} align="center">
                          Time Stamp
                        </TableCell>
                        <TableCell className={classes.noBorderBottom} align="center">
                          Uploaded By
                        </TableCell>
                        <TableCell className={classes.noBorderBottom} align="right">
                          &nbsp;&nbsp;&nbsp;
                        </TableCell>
                        <TableCell className={classes.noBorderBottom} align="right">
                          &nbsp;&nbsp;&nbsp;
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {state?.files?.map((row, i) => (
                        <TableRow key={row.uploadedDttm + i}>
                          <TableCell id={'file-name-' + uuid()} key={'key-file-name-' + uuid()} className={classes.noBorderBottom} align="center">
                            {row?.s3FileUrl.replace(/^.*[\\/]/, '')}
                          </TableCell>
                          <TableCell className={classes.noBorderBottom} align="center">
                            {moment(new Date(row.uploadedDttm)).format('MM/DD/YYYY hh:mm A')}
                          </TableCell>
                          <TableCell className={classes.noBorderBottom} align="center">
                            {row?.uploadedByFirstname + ' ' + row?.uploadedByLastname}
                          </TableCell>
                          <TableCell
                            id={'download-button-' + uuid()}
                            key={'key-download-button-' + uuid()}
                            className={classes.noBorderBottom}
                            align="right"
                            onClick={() => {
                              downloadMdFile(row);
                            }}>
                            <SystemUpdateAltRoundedIcon fontSize="small" />
                          </TableCell>
                          {isFileModificationAllowed && (
                            <TableCell
                              id={'delete-button-' + uuid()}
                              key={'key-delete-button-' + uuid()}
                              className={classes.noBorderBottom}
                              align="left"
                              onClick={() => {
                                handleDelete(row);
                              }}>
                              <DeleteForeverOutlinedIcon />
                            </TableCell>
                          )}
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              ) : (
                <Typography variant="body1">{'No file has been uploaded'}</Typography>
              )}
            </Grid>
            <Grid item xs={1} key={'icon-button-file-case-'+rowData.caseId}>
              {isFileModificationAllowed && 
                <div>
                  <input id={'icon-button-file-case-'+rowData.caseId} key={'key-' + uuid()} type="file" hidden onChange={handleFileUpload} />
                  <label htmlFor={'icon-button-file-case-'+rowData.caseId}>
                    <IconButton id={'uploaded-file-add'+rowData.caseId} key={'key-' + uuid()} aria-label="Add New" component="span">
                      <Add style={{ backgroundColor: 'green', fill: 'white' }} />
                    </IconButton>
                  </label>
                </div>
              }
            </Grid>
            <Grid item xs={10}></Grid>
            <Grid item xs={2}>
              {isFileModificationAllowed && <UploadedFileSubmit caseId={rowData.caseId} fileListSize={state?.files?.length} />}
            </Grid>
            <Grid item xs={12}>
              <div align="center">
                <p style={{ fontSize: '0.8rem' }}>
                  For any changes to the uploaded files, please reach out to <a href="mailto: insights-renewals@multiplan.com"> Insights Renewals </a>.
                </p>
              </div>
            </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>
    </>
  );
};

export default UploadedFileDetail;