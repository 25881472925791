import React from 'react';
import PropTypes from 'prop-types';
import MaterialLinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';
import { styled } from '@mui/material/styles';

const LinearProgress = (props) => {
  const StyledLinearProgress = styled(MaterialLinearProgress)(({ theme }) => ({
    height: 25,
    width: '100%',
    borderRadius: 3,
    backgroundColor: '#E8E8E8',
    transition: 'none !important',
    [`& .${linearProgressClasses.bar}`]: {
      borderRadius: 3,
      backgroundColor: `${props.barcolor}`,
      transition: 'none !important'
    }
  }));

  return <StyledLinearProgress {...props} />;
};

LinearProgress.propTypes = {
  barcolor: PropTypes.string
};

export default LinearProgress;