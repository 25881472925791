import { Checkbox, FormControlLabel, Grid, TextField } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import axios from 'axios';
import { confirmDialog } from 'common/ComfirmationDialog';
import * as Constant from 'common/Constant';
import { RENEWAL_ACCOUNT_TYPE_OPTIONS } from 'common/Constant';
import { useEffect, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useSelector } from 'react-redux';
import * as ValidationUtil from 'utils/ValidationUtil';
import { AppConfig } from '../../../../AppConfig';

const TenantCreationDetail = () => {
  const { control, getValues, setValue } = useFormContext();
  const currentId = getValues('id');
  const stateList = useSelector((state) => state.tenantManagement.stateList);
  const [brokerOptions, setBrokerOptions] = useState([]);
  const [stateOptions, setStateOptions] = useState([]);
  const brokerList = useSelector((state) => state.user.allBrokers);

  const onInputChange = (inputValue) => {
    const code = generateValidCode(inputValue);
    setValue('code', code);
  };
  const generateValidCode = (code) => {
    // Replace all uppercase characters with lowercase and whitespace and symbols with ''
    return code.replace(/[^a-zA-Z0-9_.]/g, '').toLowerCase();
  };

  useEffect(() => {
    if (brokerList && Array.isArray(brokerList)) {
      setBrokerOptions(brokerList.sort((a, b) => (a.name?.toLowerCase() < b.name?.toLowerCase() ? -1 : 0)));
      if (currentId) {
        const selectedBroker = brokerList.find((broker) => broker.id === getValues('brokerId'));
        setValue('brokerId', selectedBroker);
      }
    }
  }, [brokerList]);

  useEffect(() => {
    if (stateList && Array.isArray(stateList)) {
      setStateOptions(stateList.sort((a, b) => (a.state?.toLowerCase() < b.state?.toLowerCase() ? -1 : 0)));
      if (currentId) {
        const selectedState = stateList.find((state) => state.stateId === getValues('stateId'));
        setValue('stateId', selectedState);
      }
    }
  }, [stateList]);

  useEffect(() => {
    if (RENEWAL_ACCOUNT_TYPE_OPTIONS && Array.isArray(RENEWAL_ACCOUNT_TYPE_OPTIONS)) {
      if (currentId) {
        const selectedType = RENEWAL_ACCOUNT_TYPE_OPTIONS.find((state) => state.value === getValues('renewalAccountType'));
        setValue('renewalAccountType', selectedType);
      }
    }
  }, [RENEWAL_ACCOUNT_TYPE_OPTIONS]);

  return (
    <>
      <Grid container spacing={4} bgcolor="primary.main">
        <Grid item xs={6}>
          <Controller
            rules={{
              required: Constant.ERROR_MSG_REQUIRED_FIELD,
              validate: async (value) => {
                const found = await axios.post(`${AppConfig.FO_INITIAL_URL}/company-admin/find-by-name`, { tenantName: value },  AppConfig.POST_HEADERS);
                return ValidationUtil.VALIDATION_DUPLICATION(found, parseInt(getValues('id')), 'Duplicated tenant name is found.');
              }
            }}
            render={({ field, fieldState: { error } }) => (
              <TextField
                id="input-name"
                required
                fullWidth
                {...field}
                error={error}
                helperText={error?.message ?? null}
                label="Name"
                onChange={(event) => {
                  const value = event.target.value;
                  field.onChange(value);
                  if (!currentId) {
                    onInputChange(value);
                  }
                }}
              />
            )}
            name="name"
            control={control}
          />
        </Grid>
        <Grid item xs={6}>
          <Controller
            rules={{
              required: Constant.ERROR_MSG_REQUIRED_FIELD,
              validate: {
                rule1: (value) => {
                  if (!currentId && value.includes(' ')) {
                    return 'Code cannot have white space!';
                  }
                },
                rule2: async (value) => {
                  if (!currentId) {
                    const found = await axios.post(`${AppConfig.FO_INITIAL_URL}/company-admin/find-by-code`, { tenantCode: value },  AppConfig.POST_HEADERS);

                    return ValidationUtil.VALIDATION_DUPLICATION(found, parseInt(getValues('id')), 'Duplicated tenant code is found.');
                  }
                }
              }
            }}
            render={({ field, fieldState: { error } }) => (
              <TextField
                id="input-code"
                required
                fullWidth
                {...field}
                disabled={currentId}
                error={error}
                value={field.value || ''}
                helperText={error?.message ?? null}
                label="Code"
                onChange={(e) => onInputChange(e.target.value, field)}
              />
            )}
            name="code"
            control={control}
          />
        </Grid>

        <Grid item xs={12}>
          <Controller
            render={({ field, fieldState: { error } }) => (
              <TextField id="input-address" fullWidth {...field} error={error} helperText={error?.message ?? null} label="Address" multiline />
            )}
            name="address"
            control={control}
          />
        </Grid>

        <Grid item xs={6}>
          <Controller
            name="stateId"
            control={control}
            render={({ field, fieldState: { error } }) => (
              <Autocomplete
                {...field}
                id="input-stateId"
                options={stateOptions}
                filterSelectedOptions
                getOptionLabel={(option) => option.state}
                renderOption={(option) => option.state}
                renderInput={(params) => <TextField {...params} label="State" inputProps={{ ...params.inputProps }} />}
                onChange={(_, data) => {
                  field.onChange(data);
                }}
              />
            )}
          />
        </Grid>
        <Grid item xs={6}>
          <Controller
            name="brokerId"
            control={control}
            render={({ field, fieldState: { error } }) => (
              <Autocomplete
                {...field}
                id="input-brokerId"
                options={brokerOptions}
                filterSelectedOptions
                getOptionLabel={(option) => option.name}
                renderOption={(option) => option.name}
                renderInput={(params) => <TextField {...params} label="Broker" inputProps={{ ...params.inputProps }} />}
                onChange={(_, data) => {
                  field.onChange(data);
                }}
              />
            )}
          />
        </Grid>
        <Grid item xs={6}>
          <Controller
            rules={{
              required: Constant.ERROR_MSG_REQUIRED_FIELD
            }}
            name="renewalAccountType"
            control={control}
            render={({ field, fieldState: { error } }) => (
              <Autocomplete
                {...field}
                id="input-renewalAccountType"
                options={RENEWAL_ACCOUNT_TYPE_OPTIONS}
                filterSelectedOptions
                getOptionLabel={(option) => option.name}
                getOptionSelected={(option, value) => option.value === value}
                renderOption={(option) => option.name}
                renderInput={(params) => (
                  <TextField {...params} label="Renewal Account Type" inputProps={{ ...params.inputProps }} required error={error} helperText={error ? error.message : null} />
                )}
                onChange={(_, data) => {
                  if(currentId) {                  
                    confirmDialog(
                      () => {
                        field.onChange(data);
                      },
                      `Please confirm your selection, these changes will affect case processing.`,
                      'Confirm Selection',
                      'Cancel',
                      'Confirm',
                      () => {}
                    );
                  } else {
                    field.onChange(data);
                  }
                }}
              />
            )}
          />
        </Grid>
        <Grid item xs={6}></Grid>
        {!currentId && (
          <>
            <Grid item lg={6}>
              <Controller
                render={({ field, fieldState: { error } }) => (
                  <FormControlLabel {...field} control={<Checkbox color="primary" defaultChecked />} label="Active" labelPlacement="end" />
                )}
                defaultValue={true}
                control={control}
                name="active"
              />
            </Grid>
            {/* <Grid item lg={6}>
              <Controller
                render={({ field, fieldState: { error } }) => (
                  <FormControlLabel {...field} control={<Checkbox color="primary" defaultChecked />} label="Live" labelPlacement="end" />
                )}
                defaultValue={true}
                control={control}
                name="live"
              />
            </Grid> */}
          </>
        )}
      </Grid>
    </>
  );
};

export default TenantCreationDetail;
